import { Helmet } from 'react-helmet-async';
import { useState } from 'react';
// @mui
import { Container, Stack, Typography, Grid, Button } from '@mui/material';
import Divider from '@mui/material/Divider';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import AccountCircle from '@mui/icons-material/AccountCircle';
import SearchIcon from '@mui/icons-material/Search';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Input from '@mui/material/Input';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import * as React from 'react';
import { initializeApp } from "firebase/app";
import { getFirestore, doc, setDoc, serverTimestamp, Timestamp, getDocs, collection  } from "firebase/firestore";
import { getDatabase, ref as dbref, runTransaction, set, child, get, update } from "firebase/database";

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import moment from 'moment';
import PhoneInput from 'react-phone-input-2'
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';

const firebaseConfig = {
  apiKey: "AIzaSyAVgHwu_fzmXe1vQzrPFUPBP4pnx2Zr8IQ",
  authDomain: "azteq-dc1d6.firebaseapp.com",
  databaseURL: "https://azteq-dc1d6-default-rtdb.firebaseio.com",
  projectId: "azteq-dc1d6",
  storageBucket: "azteq-dc1d6.firebasestorage.app",
  messagingSenderId: "747452880628",
  appId: "1:747452880628:web:9b5d8dbf3cfc0bf7f630a1"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);


// Initialize Cloud Firestore and get a reference to the service
const db = getFirestore(app);
const rtdb = getDatabase(app);

// ----------------------------------------------------------------------

export default function SearchRegistration() {

  const [loading, setLoading] = React.useState(false);

  const [mobileError, setMobileError] = React.useState(false);
  const [serialError, setSerialError] = React.useState(false);

  const [regId, setRegId] = React.useState('');
  const [regCountry, setRegCountry] = React.useState('');
  const [regfullname, setRegfullname] = React.useState('');
  const [regSCExpiredDate, setRegSCExpiredDate] = React.useState('');
  const [regSCCoverDate, setRegSCCoverDate] = React.useState('');

  const [phoneModel, setPhoneModel] = React.useState('');
  const [phoneColor, setPhoneColor] = React.useState('');
  const [phoneCapacity, setPhoneCapacity] = React.useState('');

  const [mobileNum, setMobileNum] = React.useState('');

  const [open, setOpen] = React.useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const renderPhoneModel = (txt) => {

    let _text = "";

    if(txt === "iphone 16"){
      _text =  "iPhone 16";
    }

    if(txt === "iphone 16 plus"){
      _text =  "iPhone 16 Plus";
    }

    if(txt === "iphone 16 pro"){
      _text =  "iPhone 16 Pro";
    }

    if(txt === "iphone 16 pro max"){
      _text =  "iPhone 16 Pro Max";
    }

    return _text;
  }

  const handleChangeMobileNum = (event) => {
    setMobileNum(event)
  };

   const handleSubmit = async (event) => {
     event.preventDefault();
 
     let serialPass = false;
     let mobilePass = false;
 
     const data = new FormData(event.currentTarget);
 
     if(mobileNum.length > 6 ){
       setMobileError(false)
       mobilePass = true;
     }
     else
     {
       setMobileError(true)
     }
 
     if(data.get('serialNo').length > 8){
       setSerialError(false)
       serialPass = true;
     }
     else
     {
       setSerialError(true)
     }
 
     if(mobilePass === true && serialPass === true){
       
       setLoading(true)
 
       const arr = [];
       const allmobiles = [];
       let mobileFound = false;
       let mobileRecord = null;
 
       let serialFound = false;
       let serialRecord = null;
 
       get(child(dbref(rtdb), `device-registration`)).then((snapshot) => {
         if (snapshot.exists()) {
 
           snapshot.forEach((doc) => {
 
             arr.push({
               ...doc.val(),
               id: doc.key,
             });
 
           })
 
           arr.forEach((val)=>{
 
             if(val.mobile === mobileNum.trim()){
 
               
               mobileFound = true;
               mobileRecord = val;
               allmobiles.push(val)
             }
     
             if(val.serialno.toUpperCase() === data.get('serialNo').trim().toUpperCase()){
               
               
               serialFound = true;
               serialRecord = val;
             }
           })
     
           
           if(mobileFound)
           {
             setMobileError(false)
           }
           else{
             setMobileError(true)
             setLoading(false)
           }
     
           
           if(serialFound)
           {
             setSerialError(false)
           }
           else{
             setSerialError(true)
             setLoading(false)
           }
 
           if(allmobiles.length > 0)
           {
             
             let tallyRecord = false;
             allmobiles.forEach(val =>{
               if(val.id === serialRecord.id)
               {
                 tallyRecord = true;
                 mobileRecord = val;
               }
             })
 
             if(tallyRecord)
             {
               setRegId(mobileRecord.id)
     
               setRegCountry(mobileRecord.country)
               setRegfullname(mobileRecord.full_name)
               setPhoneModel(mobileRecord.phone_model)
               setPhoneColor(mobileRecord.phone_color)
               setPhoneCapacity(mobileRecord.phone_capacity)
 
               setRegSCExpiredDate(moment(mobileRecord.purchase_date, "YYYY-MM-DD").add(1, "years").format("YYYY-MM-DD"))
               setRegSCCoverDate(moment(mobileRecord.purchase_date, "YYYY-MM-DD").format("YYYY-MM-DD"))
               setOpen(true)
               setLoading(false)
             }
             else
             {
               setLoading(false)
             }
             
             
           }
           else{
             setLoading(false)
           }
     
         }
       })
       
     }
 
 
     
 
   };


  return (
    <>
      <Helmet>
        <title> Search Screen Replacement Warranty Registration | Falcon </title>
      </Helmet>

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Your Information"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
              <Grid style={{ width: "100%", display: "flex",
            flexDirection: "column" }}>
              <Typography align='left' style={{ fontSize: 14, marginTop: 15 }}>
              Register ID
              </Typography>
              <Typography align='left' style={{ fontWeight: 700, fontSize: 16 }}>
              {regId}
              </Typography>
              <Typography align='left' style={{ fontSize: 14,marginTop: 15 }}>
              Full Name
              </Typography>
              <Typography align='left' style={{ fontWeight: 700, fontSize: 16 }}>
              {regfullname}
              </Typography>
              <Typography align='left' style={{ fontSize: 14, marginTop: 15 }}>
              Country
              </Typography>
              <Typography align='left' style={{ fontWeight: 700, fontSize: 16 }}>
              {regCountry === "cambodia" ? "Cambodia" : "-"}
              </Typography>

              <Typography align='left' style={{ fontSize: 14, marginTop: 15 }}>
              Phone Model
              </Typography>
              <Typography align='left' style={{ fontWeight: 700, fontSize: 16 }}>
              {renderPhoneModel(phoneModel)}
              </Typography>

              <Typography align='left' style={{ fontSize: 14, marginTop: 15 }}>
              Phone Color
              </Typography>
              <Typography align='left' style={{ fontWeight: 700, fontSize: 16 }}>
              {phoneColor}
              </Typography>

              <Typography align='left' style={{ fontSize: 14, marginTop: 15 }}>
              Phone Capacity
              </Typography>
              <Typography align='left' style={{ fontWeight: 700, fontSize: 16 }}>
              {phoneCapacity}
              </Typography>

              <Typography align='left' style={{ fontSize: 14, marginTop: 15 }}>
              Screen Replacement Covered Date
              </Typography>
              <Typography align='left' style={{ fontWeight: 700, fontSize: 16 }}>
              {regSCCoverDate}
              </Typography>
              <Typography align='left' style={{ fontSize: 14, marginTop: 15 }}>
              Screen Replacement Expired Date
              </Typography>
              <Typography align='left' style={{ fontWeight: 700, fontSize: 16 }}>
              {regSCExpiredDate}
              </Typography>
            </Grid>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Close</Button>
        </DialogActions>
      </Dialog>

      <Grid style={{ width: "100%", display: "flex", alignItems: "center",  flexDirection: "column"  }}>


        <Grid style={{ width: "100%", marginTop: 60, maxWidth: 1200, display: "flex", padding: 25,  flexDirection: "column", alignItems: "center" }}>
          
        

          <Typography style={{ fontWeight: 700, fontSize: 30, }}>
          ស្វែងរកការចុះឈ្មោះធានារបស់អ្នក។
            </Typography>
            <Box component="form" noValidate onSubmit={handleSubmit} sx={{ display: "flex", width: "100%", flexDirection: "column", alignItems: "center" }}>
              <TextField
                style={{ marginTop: 20, width: "100%" }}
                id="serialNo"
                label={"លេខកូដម៉ាស៊ីន"}
                name="serialNo"
                required
                error={serialError}
                autoComplete="off"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
                variant="outlined"
              />
              {serialError ? <Typography style={{ marginTop: 10 , fontSize: 11, color: 'red' }}>
              លេខ serial មិនត្រឹមត្រូវ
          </Typography> : null }

            <PhoneInput
                country={'kh'}
                specialLabel='លេខទូរសព្ទ'
                inputStyle={{ width: "100%" }}
                style={{ margin: 0, marginTop: 15, width: "100%" }}
                value={mobileNum}
                onChange={handleChangeMobileNum}
              />  

{mobileError ? <Typography style={{ marginTop: 10 , fontSize: 11, color: 'red' }}>
លេខទូរស័ព្ទមិនត្រឹមត្រូវ
          </Typography> : null }

              {loading ?<CircularProgress sx={{ mt: 3, mb: 2 }} color="inherit" />:
              <Button
                type="submit"
                variant="contained"
                disableElevation
                sx={{ mt: 3, mb: 2 }}
              >
                ស្វែងរកការចុះឈ្មោះ
              </Button>}
            </Box>
            
        </Grid>
        

       
        
      </Grid>
    </>
  );
}
