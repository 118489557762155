import { Helmet } from 'react-helmet-async';
import { useState } from 'react';
// @mui
import { Container, Stack, Typography, Grid, Button } from '@mui/material';
import TextField from '@mui/material/TextField';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import * as React from 'react';
import Box from '@mui/material/Box';
import { initializeApp } from "firebase/app";
import { getFirestore, doc, setDoc, serverTimestamp, Timestamp, getDocs, collection, query, where } from "firebase/firestore";
import { getDatabase, ref as dbref, runTransaction, set, child, get, update, push } from "firebase/database";
import CircularProgress from '@mui/material/CircularProgress';
import dayjs from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { MuiFileInput } from 'mui-file-input'
import { getStorage, ref, uploadBytes } from "firebase/storage";

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

import PhoneInput from 'react-phone-input-2'

import 'react-phone-input-2/lib/material.css'

import moment from 'moment';

// TODO: Replace the following with your app's Firebase project configuration
// See: https://support.google.com/firebase/answer/7015592
const firebaseConfig = {
  apiKey: "AIzaSyAVgHwu_fzmXe1vQzrPFUPBP4pnx2Zr8IQ",
  authDomain: "azteq-dc1d6.firebaseapp.com",
  databaseURL: "https://azteq-dc1d6-default-rtdb.firebaseio.com",
  projectId: "azteq-dc1d6",
  storageBucket: "azteq-dc1d6.firebasestorage.app",
  messagingSenderId: "747452880628",
  appId: "1:747452880628:web:9b5d8dbf3cfc0bf7f630a1"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);


// Initialize Cloud Firestore and get a reference to the service
const db = getFirestore(app);
const rtdb = getDatabase(app);

const phonecolorlistiphone16 = [
  { id: 'black', label: 'Black' },
  { id: 'white', label: 'White' },
  { id: 'pink', label: 'Pink' },
  { id: 'ultramarine', label: 'Ultramarine' },
  { id: 'teal', label: 'Teal' }
];

const phonecolorlistiphone16pro = [
  { id: 'black titanium', label: 'Black Titanium' },
  { id: 'white titanium', label: 'White Titanium' },
  { id: 'desert titanium', label: 'Desert Titanium' },
  { id: 'natural titanium', label: 'Natural Titanium' }
];

const phonecolorlistiphone16all = [
  { id: 'black', label: 'Black' },
  { id: 'white', label: 'White' },
  { id: 'pink', label: 'Pink' },
  { id: 'ultramarine', label: 'Ultramarine' },
  { id: 'teal', label: 'Teal' },
  { id: 'black titanium', label: 'Black Titanium' },
  { id: 'white titanium', label: 'White Titanium' },
  { id: 'desert titanium', label: 'Desert Titanium' },
  { id: 'natural titanium', label: 'Natural Titanium' }
];

const phonecapacitylistiphone16 = [
  { id: '128gb', label: '128GB' },
  { id: '256gb', label: '256GB' },
  { id: '512gb', label: '512GB' }
];

const phonecapacitylistiphone16pro = [
  { id: '128gb', label: '128GB' },
  { id: '256gb', label: '256GB' },
  { id: '512gb', label: '512GB' },
  { id: '1tb', label: '1TB' }
];

const phonecapacitylistiphone16promax = [
  { id: '256gb', label: '256GB' },
  { id: '512gb', label: '512GB' },
  { id: '1tb', label: '1TB' }
];

const phonecapacitylistiphone16all= [
  { id: '128gb', label: '128GB' },
  { id: '256gb', label: '256GB' },
  { id: '512gb', label: '512GB' },
  { id: '1tb', label: '1TB' }
];

const citylist = [
  { id: 'banteay meanchey', label: 'បន្ទាយមានជ័យ' },
  { id: 'battambang', label: 'បាត់ដំបង' },
  { id: 'kampong cham', label: 'កំពង់ចាម' },
  { id: 'kampong chhnang', label: 'កំពង់ឆ្នាំង' },
  { id: 'kampong speu', label: 'កំពង់ស្ពឺ' },
  { id: 'kampong thom', label: 'កំពង់ធំ' },
  { id: 'kampot', label: 'កំពត' },
  { id: 'kandal', label: 'កណ្តាល' },
  { id: 'kep', label: 'កែប' },
  { id: 'koh kong', label: 'កោះកុង' },
  { id: 'kratié', label: 'ក្រចេះ' },
  { id: 'mondulkiri', label: 'មណ្ឌលគិរី' },
  { id: 'oddar meanchey', label: 'ឧត្តរមានជ័យ' },

  { id: 'phnom penh', label: 'ភ្នំពេញ' },
  { id: 'takéo', label: 'តាកែវ' },
  { id: 'tboung khmum', label: 'ត្បូងឃ្មុំ' },
  { id: 'pailin', label: 'ប៉ៃលិន' },
  { id: 'pursat', label: 'ពោធិ៍សាត់' },
  { id: 'preah vihear', label: 'ព្រះវិហារ' },
  { id: 'preah sihanouk', label: 'ព្រះសីហនុ' },
  { id: 'prey veng', label: 'ព្រៃវែង' },
  { id: 'ratanakiri', label: 'រតនគិរី' },
  { id: 'siem reap', label: 'សៀមរាប' },
  { id: 'stung tren', label: 'ស្ទឹងត្រែង' },
  { id: 'svay rieng', label: 'ស្វាយរៀង' },
];

const partnolist = [
  {
    "name": "iPhone 16 Plus",
    "partno": "MXVU3X/A",
    "capacity": "128GB",
    "color": "Black",
    "brand": "Apple"
  },
  {
    "name": "iPhone 16 Plus",
    "partno": "MXVV3X/A",
    "capacity": "128GB",
    "color": "White",
    "brand": "Apple"
  },
  {
    "name": "iPhone 16 Plus",
    "partno": "MXVW3X/A",
    "capacity": "128GB",
    "color": "Pink",
    "brand": "Apple"
  },
  {
    "name": "iPhone 16 Plus",
    "partno": "MXVX3X/A",
    "capacity": "128GB",
    "color": "Ultramarine",
    "brand": "Apple"
  },
  {
    "name": "iPhone 16 Plus",
    "partno": "MXVY3X/A",
    "capacity": "128GB",
    "color": "Teal",
    "brand": "Apple"
  },
  {
    "name": "iPhone 16 Plus",
    "partno": "MXWN3X/A",
    "capacity": "256GB",
    "color": "Black",
    "brand": "Apple"
  },
  {
    "name": "iPhone 16 Plus",
    "partno": "MXWV3X/A",
    "capacity": "256GB",
    "color": "White",
    "brand": "Apple"
  },
  {
    "name": "iPhone 16 Plus",
    "partno": "MXY13X/A",
    "capacity": "256GB",
    "color": "Pink",
    "brand": "Apple"
  },
  {
    "name": "iPhone 16 Plus",
    "partno": "MXY23X/A",
    "capacity": "256GB",
    "color": "Ultramarine",
    "brand": "Apple"
  },
  {
    "name": "iPhone 16 Plus",
    "partno": "MXY53X/A",
    "capacity": "256GB",
    "color": "Teal",
    "brand": "Apple"
  },
  {
    "name": "iPhone 16 Plus",
    "partno": "MY1P3X/A",
    "capacity": "512GB",
    "color": "Black",
    "brand": "Apple"
  },
  {
    "name": "iPhone 16 Plus",
    "partno": "MY1X3X/A",
    "capacity": "512GB",
    "color": "White",
    "brand": "Apple"
  },
  {
    "name": "iPhone 16 Plus",
    "partno": "MY253X/A",
    "capacity": "512GB",
    "color": "Pink",
    "brand": "Apple"
  },
  {
    "name": "iPhone 16 Plus",
    "partno": "MY2D3X/A",
    "capacity": "512GB",
    "color": "Ultramarine",
    "brand": "Apple"
  },
  {
    "name": "iPhone 16 Plus",
    "partno": "MY2J3X/A",
    "capacity": "512GB",
    "color": "Teal",
    "brand": "Apple"
  },
  {
    "name": "iPhone 16",
    "partno": "MYE73X/A",
    "capacity": "128GB",
    "color": "Black",
    "brand": "Apple"
  },
  {
    "name": "iPhone 16",
    "partno": "MYE93X/A",
    "capacity": "128GB",
    "color": "White",
    "brand": "Apple"
  },
  {
    "name": "iPhone 16",
    "partno": "MYEA3X/A",
    "capacity": "128GB",
    "color": "Pink",
    "brand": "Apple"
  },
  {
    "name": "iPhone 16",
    "partno": "MYEC3X/A",
    "capacity": "128GB",
    "color": "Ultramarine",
    "brand": "Apple"
  },
  {
    "name": "iPhone 16",
    "partno": "MYED3X/A",
    "capacity": "128GB",
    "color": "Teal",
    "brand": "Apple"
  },
  {
    "name": "iPhone 16",
    "partno": "MYEE3X/A",
    "capacity": "256GB",
    "color": "Black",
    "brand": "Apple"
  },
  {
    "name": "iPhone 16",
    "partno": "MYEF3X/A",
    "capacity": "256GB",
    "color": "White",
    "brand": "Apple"
  },
  {
    "name": "iPhone 16",
    "partno": "MYEG3X/A",
    "capacity": "256GB",
    "color": "Pink",
    "brand": "Apple"
  },
  {
    "name": "iPhone 16",
    "partno": "MYEH3X/A",
    "capacity": "256GB",
    "color": "Ultramarine",
    "brand": "Apple"
  },
  {
    "name": "iPhone 16",
    "partno": "MYEJ3X/A",
    "capacity": "256GB",
    "color": "Teal",
    "brand": "Apple"
  },
  {
    "name": "iPhone 16",
    "partno": "MYEK3X/A",
    "capacity": "512GB",
    "color": "Black",
    "brand": "Apple"
  },
  {
    "name": "iPhone 16",
    "partno": "MYEP3X/A",
    "capacity": "512GB",
    "color": "White",
    "brand": "Apple"
  },
  {
    "name": "iPhone 16",
    "partno": "MYEQ3X/A",
    "capacity": "512GB",
    "color": "Pink",
    "brand": "Apple"
  },
  {
    "name": "iPhone 16",
    "partno": "MYER3X/A",
    "capacity": "512GB",
    "color": "Ultramarine",
    "brand": "Apple"
  },
  {
    "name": "iPhone 16",
    "partno": "MYEU3X/A",
    "capacity": "512GB",
    "color": "Teal",
    "brand": "Apple"
  },
  {
    "name": "iPhone 16 Pro",
    "partno": "MYND3X/A",
    "capacity": "128GB",
    "color": "Black Titanium",
    "brand": "Apple"
  },
  {
    "name": "iPhone 16 Pro",
    "partno": "MYNE3X/A",
    "capacity": "128GB",
    "color": "White Titanium",
    "brand": "Apple"
  },
  {
    "name": "iPhone 16 Pro",
    "partno": "MYNF3X/A",
    "capacity": "128GB",
    "color": "Desert Titanium",
    "brand": "Apple"
  },
  {
    "name": "iPhone 16 Pro",
    "partno": "MYNG3X/A",
    "capacity": "128GB",
    "color": "Natural Titanium",
    "brand": "Apple"
  },
  {
    "name": "iPhone 16 Pro",
    "partno": "MYNH3X/A",
    "capacity": "256GB",
    "color": "Black Titanium",
    "brand": "Apple"
  },
  {
    "name": "iPhone 16 Pro",
    "partno": "MYNJ3X/A",
    "capacity": "256GB",
    "color": "White Titanium",
    "brand": "Apple"
  },
  {
    "name": "iPhone 16 Pro",
    "partno": "MYNK3X/A",
    "capacity": "256GB",
    "color": "Desert Titanium",
    "brand": "Apple"
  },
  {
    "name": "iPhone 16 Pro",
    "partno": "MYNL3X/A",
    "capacity": "256GB",
    "color": "Natural Titanium",
    "brand": "Apple"
  },
  {
    "name": "iPhone 16 Pro",
    "partno": "MYNM3X/A",
    "capacity": "512GB",
    "color": "Black Titanium",
    "brand": "Apple"
  },
  {
    "name": "iPhone 16 Pro",
    "partno": "MYNN3X/A",
    "capacity": "512GB",
    "color": "White Titanium",
    "brand": "Apple"
  },
  {
    "name": "iPhone 16 Pro",
    "partno": "MYNP3X/A",
    "capacity": "512GB",
    "color": "Desert Titanium",
    "brand": "Apple"
  },
  {
    "name": "iPhone 16 Pro",
    "partno": "MYNQ3X/A",
    "capacity": "512GB",
    "color": "Natural Titanium",
    "brand": "Apple"
  },
  {
    "name": "iPhone 16 Pro",
    "partno": "MYNR3X/A",
    "capacity": "1TB",
    "color": "Black Titanium",
    "brand": "Apple"
  },
  {
    "name": "iPhone 16 Pro",
    "partno": "MYNT3X/A",
    "capacity": "1TB",
    "color": "White Titanium",
    "brand": "Apple"
  },
  {
    "name": "iPhone 16 Pro",
    "partno": "MYNW3X/A",
    "capacity": "1TB",
    "color": "Desert Titanium",
    "brand": "Apple"
  },
  {
    "name": "iPhone 16 Pro",
    "partno": "MYNX3X/A",
    "capacity": "1TB",
    "color": "Natural Titanium",
    "brand": "Apple"
  },
  {
    "name": "iPhone 16 Pro Max",
    "partno": "MYWV3X/A",
    "capacity": "256GB",
    "color": "Black Titanium",
    "brand": "Apple"
  },
  {
    "name": "iPhone 16 Pro Max",
    "partno": "MYWW3X/A",
    "capacity": "256GB",
    "color": "White Titanium",
    "brand": "Apple"
  },
  {
    "name": "iPhone 16 Pro Max",
    "partno": "MYWX3X/A",
    "capacity": "256GB",
    "color": "Desert Titanium",
    "brand": "Apple"
  },
  {
    "name": "iPhone 16 Pro Max",
    "partno": "MYWY3X/A",
    "capacity": "256GB",
    "color": "Natural Titanium",
    "brand": "Apple"
  },
  {
    "name": "iPhone 16 Pro Max",
    "partno": "MYX03X/A",
    "capacity": "512GB",
    "color": "Black Titanium",
    "brand": "Apple"
  },
  {
    "name": "iPhone 16 Pro Max",
    "partno": "MYX13X/A",
    "capacity": "512GB",
    "color": "White Titanium",
    "brand": "Apple"
  },
  {
    "name": "iPhone 16 Pro Max",
    "partno": "MYX23X/A",
    "capacity": "512GB",
    "color": "Desert Titanium",
    "brand": "Apple"
  },
  {
    "name": "iPhone 16 Pro Max",
    "partno": "MYX33X/A",
    "capacity": "512GB",
    "color": "Natural Titanium",
    "brand": "Apple"
  },
  {
    "name": "iPhone 16 Pro Max",
    "partno": "MYX43X/A",
    "capacity": "1TB",
    "color": "Black Titanium",
    "brand": "Apple"
  },
  {
    "name": "iPhone 16 Pro Max",
    "partno": "MYX53X/A",
    "capacity": "1TB",
    "color": "White Titanium",
    "brand": "Apple"
  },
  {
    "name": "iPhone 16 Pro Max",
    "partno": "MYX63X/A",
    "capacity": "1TB",
    "color": "Desert Titanium",
    "brand": "Apple"
  },
  {
    "name": "iPhone 16 Pro Max",
    "partno": "MYX73X/A",
    "capacity": "1TB",
    "color": "Natural Titanium",
    "brand": "Apple"
  }
]


// ----------------------------------------------------------------------

export default function SearchRegistration() {

  const [fullname, setFullname] = React.useState('');
  const [clientId, setClientId] = React.useState('');
  const [email, setEmail] = React.useState('');
  const [mobileNum, setMobileNum] = React.useState('');
  const [city, setCity] = React.useState('');
  const [serialNumber, setSerialNumber] = React.useState('');
  const [agreement, setAgreement] = React.useState(false);
  const [date, setDate] = React.useState(dayjs());
  const [file, setFile] = React.useState(null);

  const [fullnameError, setFullnameError] = React.useState(false);
  const [emailError, setEmailError] = React.useState(false);
  const [cityError, setCityError] = React.useState(false);
  const [serialNumberError, setSerialNumberError] = React.useState(false);
  const [mobileNumError, setMobileNumError] = React.useState(false);
  const [fileInputError, setFileInputError] = React.useState(false);
  const [serialNoRegistered, setSerialNoRegistered] = React.useState(false);

  const [currentCountryCode, setCurrentCountryCode] = React.useState("KH");
  const [currentCountry, setCurrentCountry] = React.useState("cambodia");
  const [currentCountryName, setCurrentCountryName] = React.useState("Cambodia");
  const [termName, setTermName] = React.useState("Falcon");

  const [loading, setLoading] = React.useState(false);
  const [thankyou, setThankyou] = React.useState(false);

  const [open, setOpen] = React.useState(false);

  const [phoneModel, setPhoneModel] = React.useState(null);
  const [phoneColor, setPhoneColor] = React.useState(null);
  const [phoneCapacity, setPhoneCapacity] = React.useState(null);
  const [partno, setPartNo] = React.useState(null);
  const [phoneModelerror, setPhoneModelerror] = React.useState(false);
  const [phoneColorerror, setPhoneColorerror] = React.useState(false);
  const [phoneCapacityerror, setPhoneCapacityerror] = React.useState(false);


  const handleChangePhoneModel = (event) => {
    setPhoneColor(null)
    setPhoneCapacity(null)
    setPhoneModel(event.target.value);
  };

  const handleChangePhoneColor = (event) => {
    setPhoneColor(event.target.value);
  };

  const handleChangePhoneCapacity = (event) => {
    setPhoneCapacity(event.target.value);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleOnChangeUploadfile = (newFile) => {
    setFile(newFile);
  };

  const handleChangeAgreement = (event) => {
    setAgreement(event.target.checked)
  };

  const handleChangeFullname = (event) => {
    setFullname(event.target.value)
  };

  const handleChangeClientId = (event) => {
    setClientId(event.target.value)
  };

  const handleChangeEmail = (event) => {
    setEmail(event.target.value.trim())
  };

  const handleChangeMobileNum = (event) => {
    setMobileNum(event.trim())
  };

  const handleChangeCity = (event) => {
    setCity(event.target.value)
  };

  const handleChangeSerialNumber = (event) => {
    setSerialNumber(event.target.value)

    if(event.target.value &&  event.target.value.length > 7)
      {
        get(child(dbref(rtdb), `serialno/${event.target.value.toUpperCase().trim()}`)).then((snapshot) => {
          if (snapshot.exists()) {
    
            if(snapshot.val().serialno.toUpperCase() === event.target.value.toUpperCase().trim())
            {
              const currentIndex = partnolist.map(val=> val.partno).indexOf(snapshot.val().partno.toUpperCase().trim());
  
              if(currentIndex !== -1){
                setPartNo(partnolist[currentIndex].partno.toUpperCase().trim());
                setPhoneModel(partnolist[currentIndex].name.toLocaleLowerCase().trim())
                setPhoneColor(partnolist[currentIndex].color.toLocaleLowerCase().trim())
                setPhoneCapacity(partnolist[currentIndex].capacity.toLocaleLowerCase().trim())
              }
              else
              {
                setPartNo(null)
                setPhoneModel(null)
                setPhoneColor(null)
                setPhoneCapacity(null)
              }
            }
            else
            {
              setPartNo(null)
              setPhoneModel(null)
              setPhoneColor(null)
              setPhoneCapacity(null)
            }
          }
          else
          {
            setPartNo(null)
            setPhoneModel(null)
            setPhoneColor(null)
            setPhoneCapacity(null)
          }
        });
      }
      else
      {
        setPartNo(null)
        setPhoneModel(null)
        setPhoneColor(null)
        setPhoneCapacity(null)
      }
  };

  const saveData = () => {
    setLoading(true);

    const storage = getStorage(app);
    const storageRef = ref(storage, `registration_warrantycard/${Timestamp.fromDate(new Date()).toMillis()}_${file.name.replace(/\s/g,'')}`);
    
    let sequenceID = 0;

      get(child(dbref(rtdb), `device-registration-sequence`)).then((snapshot) => {
        if (snapshot.exists()) {
          sequenceID = snapshot.val().count + 1;
          set(child(dbref(rtdb), `device-registration-sequence`), { count: sequenceID })
         
        } else {
          set(child(dbref(rtdb), `device-registration-sequence`), { count: 1})
          sequenceID = 1;
        }

        const ID = `FC-SRW-${leftPad(sequenceID, 7)}`;
        
        // 'file' comes from the Blob or File API
        uploadBytes(storageRef, file).then((snapshot) => {

          try
          {
            set(dbref(rtdb, `device-registration/${ID}`), {
              full_name: fullname || null,
              id: ID,
              email: email || null,
              file_fullPath: snapshot.metadata.fullPath || null,
              file_contentType: snapshot.metadata.contentType || null,
              city: city || null,
              mobile: mobileNum,
              country: currentCountry,
              serialno: serialNumber ||  null,
              purchase_date: date.$d.toISOString().substring(0, 10) || null,
              created: Timestamp.fromDate(new Date()).seconds,
              status: "pending_verification",
              phone_partno: partno,
              phone_model: phoneModel,
              phone_color: phoneColor,
              phone_capacity: phoneCapacity,
            }).then(()=>{
              
              const emailID = `${currentCountryCode}${Timestamp.fromDate(new Date()).toMillis()}`;
              try
              {
                setDoc(doc(db, "mail", emailID), {
                  to: email,
                  message: {
                    subject: 'Screen Replacement Warranty Successful Registration',
                    html: `<!DOCTYPE html>
                    <html>
                    <head>
                    
                      <meta charset="utf-8">
                      <meta http-equiv="x-ua-compatible" content="ie=edge">
                      <title>Screen Replacement Warranty Successful Registration</title>
                      <meta name="viewport" content="width=device-width, initial-scale=1">
                      <style type="text/css">
                      /**
                       * Google webfonts. Recommended to include the .woff version for cross-client compatibility.
                       */
                      @media screen {
                        @font-face {
                          font-family: 'Source Sans Pro';
                          font-style: normal;
                          font-weight: 400;
                          src: local('Source Sans Pro Regular'), local('SourceSansPro-Regular'), url(https://fonts.gstatic.com/s/sourcesanspro/v10/ODelI1aHBYDBqgeIAH2zlBM0YzuT7MdOe03otPbuUS0.woff) format('woff');
                        }
                        @font-face {
                          font-family: 'Source Sans Pro';
                          font-style: normal;
                          font-weight: 700;
                          src: local('Source Sans Pro Bold'), local('SourceSansPro-Bold'), url(https://fonts.gstatic.com/s/sourcesanspro/v10/toadOcfmlt9b38dHJxOBGFkQc6VGVFSmCnC_l7QZG60.woff) format('woff');
                        }
                      }
                      /**
                       * Avoid browser level font resizing.
                       * 1. Windows Mobile
                       * 2. iOS / OSX
                       */
                      body,
                      table,
                      td,
                      a {
                        -ms-text-size-adjust: 100%; /* 1 */
                        -webkit-text-size-adjust: 100%; /* 2 */
                      }
                      /**
                       * Remove extra space added to tables and cells in Outlook.
                       */
                      table,
                      td {
                        mso-table-rspace: 0pt;
                        mso-table-lspace: 0pt;
                      }
                      /**
                       * Better fluid images in Internet Explorer.
                       */
                      img {
                        -ms-interpolation-mode: bicubic;
                      }
                      /**
                       * Remove blue links for iOS devices.
                       */
                      a[x-apple-data-detectors] {
                        font-family: inherit !important;
                        font-size: inherit !important;
                        font-weight: inherit !important;
                        line-height: inherit !important;
                        color: inherit !important;
                        text-decoration: none !important;
                      }
                      /**
                       * Fix centering issues in Android 4.4.
                       */
                      div[style*="margin: 16px 0;"] {
                        margin: 0 !important;
                      }
                      body {
                        width: 100% !important;
                        height: 100% !important;
                        padding: 0 !important;
                        margin: 0 !important;
                      }
                      /**
                       * Collapse table borders to avoid space between cells.
                       */
                      table {
                        border-collapse: collapse !important;
                      }
                      a {
                        color: #1a82e2;
                      }
                      img {
                        height: auto;
                        line-height: 100%;
                        text-decoration: none;
                        border: 0;
                        outline: none;
                      }
                      </style>
                    
                    </head>
                    <body style="background-color: #e9ecef;">
                    
                      <!-- start preheader -->
                      <div class="preheader" style="display: none; max-width: 0; max-height: 0; overflow: hidden; font-size: 1px; line-height: 1px; color: #fff; opacity: 0;">
                      Thank you for completing your registration of your protection care plan for your iPhone.
                      </div>
                      <!-- end preheader -->
                    
                      <!-- start body -->
                      <table border="0" cellpadding="0" cellspacing="0" width="100%">
                    
                        <!-- start logo -->
                        <tr>
                          <td align="center" bgcolor="#e9ecef">
                            <!--[if (gte mso 9)|(IE)]>
                            <table align="center" border="0" cellpadding="0" cellspacing="0" width="600">
                            <tr>
                            <td align="center" valign="top" width="600">
                            <![endif]-->
                            <table border="0" cellpadding="0" cellspacing="0" width="100%" style="max-width: 600px;">
                              <tr>
                                <td align="center" valign="top" style="padding: 36px 24px;">
                                  <a href="https://screenwarranty.falconkh.com" target="_blank" style="display: inline-block;">
                                    <img src="https://firebasestorage.googleapis.com/v0/b/azteq-dc1d6.firebasestorage.app/o/logo%2FFalcon-logo-small.png?alt=media&token=c9c979cd-185d-41d3-9a16-972a5dcfeed5" alt="Logo" border="0" width="150" style="display: block; width: 150px; max-width: 150px; min-width: 48px;">
                                  </a>
                                </td>
                              </tr>
                            </table>
                            <!--[if (gte mso 9)|(IE)]>
                            </td>
                            </tr>
                            </table>
                            <![endif]-->
                          </td>
                        </tr>
                        <!-- end logo -->
                    
                        <!-- start copy block -->
                        <tr>
                          <td align="center" bgcolor="#e9ecef">
                            <!--[if (gte mso 9)|(IE)]>
                            <table align="center" border="0" cellpadding="0" cellspacing="0" width="600">
                            <tr>
                            <td align="center" valign="top" width="600">
                            <![endif]-->
                            <table border="0" cellpadding="0" cellspacing="0" width="100%" style="max-width: 600px;">

                            <!-- start copy -->
                              <tr>
                                <td align="left" bgcolor="#ffffff" style="padding: 24px; font-family: 'Source Sans Pro', Helvetica, Arial, sans-serif; font-size: 16px; line-height: 24px;">
                                  <p style="margin: 0;">Dear ${fullname},</p>
                                </td>
                              </tr>
                              <!-- end copy -->
                    
                              <!-- start copy -->
                              <tr>
                                <td align="left" bgcolor="#ffffff" style="padding: 24px; font-family: 'Source Sans Pro', Helvetica, Arial, sans-serif; font-size: 16px; line-height: 24px;">
                                  <p style="margin: 0;">Thank you for completing your registration of your protection care plan for your iPhone.</p>
                                </td>
                              </tr>
                              <!-- end copy -->
                    
                              <!-- start copy -->
                              <tr>
                                <td align="left" bgcolor="#ffffff" style="padding: 24px; font-family: 'Source Sans Pro', Helvetica, Arial, sans-serif; font-size: 16px; line-height: 24px;">
                                 <p style="margin: 0;">Name: ${fullname}</p>
                                  <p style="margin: 0;">Mobile Number: +${mobileNum}</p>
                                  <p style="margin: 0;">City: ${renderCity(city)}</p>
                                  <p style="margin: 0;">Serial Number: ${serialNumber}</p>
                                  <p style="margin: 0;">Phone Model: ${renderPhoneModel(phoneModel)}</p>
                                  <p style="margin: 0;">Phone Color: ${capitalizeFirstLetter(phoneColor)}</p>
                                  <p style="margin: 0;">Phone Capacity: ${phoneCapacity.toUpperCase()}</p>
                                  <p></p>  
                                <p style="margin: 0;">Your coverage for the iPhone is as follows:</p>
                                  <p style="margin: 0;">1. Screen Replacement Coverage From 
                                  ${moment(date.$d.toISOString().substring(0, 10), "YYYY-MM-DD").format("Do MMM YYYY")} to ${moment(date.$d.toISOString().substring(0, 10), "YYYY-MM-DD").add(1, "years").format("Do MMM YYYY")}</p>
                                </td>
                              </tr>
                              <!-- end copy -->
                    
                              <!-- start copy -->
                              <tr>
                                <td align="left" bgcolor="#ffffff" style="padding: 24px; font-family: 'Source Sans Pro', Helvetica, Arial, sans-serif; font-size: 16px; line-height: 24px; border-bottom: 3px solid #d4dadf">
                                  <p style="margin: 0;">Cheers,<br> Falcon </p>
                                </td>
                              </tr>
                              <!-- end copy -->
                    
                            </table>
                            <!--[if (gte mso 9)|(IE)]>
                            </td>
                            </tr>
                            </table>
                            <![endif]-->
                          </td>
                        </tr>
                        <!-- end copy block -->
                    
                      </table>
                      <!-- end body -->
                    
                    </body>
                    </html>`,
                  }
                }).then(()=>{

                  setLoading(false);
                  setThankyou(true);

                  setFullnameError(false);
                  setEmailError(false);
                  setCityError(false);
                  setSerialNumberError(false);
                  setFileInputError(false);
                  setMobileNumError(false);

                  setFullname('');
                  setClientId('');
                  setEmail('');
                  setCity('');
                  setSerialNumber('');
                  setDate(dayjs());
                  setFile(null);
                  setAgreement(false);

                })
              }
              catch(e)
              {
                // console.log(e)
              }
              
            
            })
          }
          catch(e)
          {
            // console.log(e)
          }
          
        });

    });
    
  }

  const leftPad = (number, targetLength) => {
    let output = `${number}`;
    while (output.length < targetLength) {
        output = `0${output}`;
    }
    return output;
  }

  const submitHandler = async (e) => {
    
    e.preventDefault();

    setLoading(true);

    let fullnamePass = false;
    // let emailPass = false;
    let cityPass = false;
    let serialNoRegPass = false;
    let serialNumberPass = false;
    let mobileNumPass = false;
    let filePass = false;

    
    if(file && (file.type === "image/jpeg" || file.type === "image/png" || file.type === "application/pdf" )){
      setFileInputError(false)
      filePass = true;
    }
    else
    {
      setFileInputError(true)
    }

    if(fullname.length > 5){
      setFullnameError(false)
      fullnamePass = true;
    }
    else
    {
      setFullnameError(true)
    }

    if(mobileNum.length > 7){
      setMobileNumError(false)
      mobileNumPass = true;
    }
    else
    {
      setMobileNumError(true)
    }


    /* const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
    if(email.length > 0 && emailRegex.test(email)){
      setEmailError(false)
      emailPass = true;
    }
    else
    {
      setEmailError(true)
    } */

    if(city.length > 2){
      setCityError(false)
      cityPass = true;
    }
    else
    {
      setCityError(true)
    }

    let phoneModelPass = false;
    let phoneColorPass = false;
    let phoneCapacityPass = false;

    if(phoneModel === null){
      setPhoneModelerror(true)
    }
    else
    {
      setPhoneModelerror(false)
      phoneModelPass= true;
    }

    if(phoneColor === null){
      setPhoneColorerror(true)
    }
    else
    {
      setPhoneColorerror(false)
      phoneColorPass= true;
    }

    if(phoneCapacity === null){
      setPhoneCapacityerror(true)
    }
    else
    {
      setPhoneCapacityerror(false)
      phoneCapacityPass= true;
    }

    let q4pass = false;

    if(serialNumber.length > 9)
    {
      get(child(dbref(rtdb), `serialno/${serialNumber.toUpperCase().trim()}`)).then((snapshot) => {
        if (snapshot.exists()) {
  
          if(snapshot.val().country.toUpperCase() === currentCountry.toUpperCase())
          {
            setSerialNumberError(false)
            serialNumberPass = true;
          }
          else
          {
            setSerialNumberError(true)
            serialNumberPass = false;
          }
  
          if(serialNumberPass)
          {
            get(child(dbref(rtdb), `device-registration`)).then((snapshot) => {
              if (snapshot.exists()) {
                
                snapshot.forEach((doc) => {
                  if(doc.val().serialno.toUpperCase().trim() === serialNumber.toUpperCase().trim() && doc.val().country.toUpperCase() === currentCountry.toUpperCase())
                  {
                    q4pass = true;
                  }
                })
    
                if(q4pass === false){
                  setSerialNumberError(false)
                  setSerialNoRegistered(false)
                  serialNoRegPass = true;
                }
                else
                {
                  setSerialNumberError(false)
                  setSerialNoRegistered(true)
                }
    
                if(fullnamePass === true && 
                  cityPass === true && 
                  mobileNumPass === true &&
                  filePass === true &&
                  serialNoRegPass === true &&
                  phoneModelPass === true &&
                  phoneColorPass === true &&
                  phoneCapacityPass === true &&
                  serialNumberPass === true && agreement === true)
                {
                    
                  setOpen(true);
                  setLoading(false);
                    
                }
                else{
                  setLoading(false);
                }
    
              }
              else
              {
                setSerialNumberError(false)
                setSerialNoRegistered(false)
                serialNoRegPass = true;
  
                if(fullnamePass === true && 
                  cityPass === true && 
                  mobileNumPass === true &&
                  filePass === true &&
                  serialNoRegPass === true &&
                  serialNumberPass === true && agreement === true)
                {
                    
            
                  setOpen(true);
                  setLoading(false);
                    
                }
                else{
                  setLoading(false);
                }
              }
            })
          }
          
  
        }
        else{
          setSerialNumberError(true)
          serialNumberPass = false;
          setLoading(false);
        }
      }) 
  
    }else{
      setSerialNumberError(true)
      serialNumberPass = false;
      setLoading(false);
    }
    

  }

  const capitalizeFirstLetter = (val) => {
    return String(val).charAt(0).toUpperCase() + String(val).slice(1);
  }

  const renderPhoneModel = (txt) => {

    let _text = "";
  
    if(txt === "iphone 16"){
      _text =  "iPhone 16";
    }
  
    if(txt === "iphone 16 plus"){
      _text =  "iPhone 16 Plus";
    }
  
    if(txt === "iphone 16 pro"){
      _text =  "iPhone 16 Pro";
    }
  
    if(txt === "iphone 16 pro max"){
      _text =  "iPhone 16 Pro Max";
    }
  
    return _text;
  }

  const renderCity = (val) => {

    let _city = "";

    const currentIndex = citylist.map(val=> val.id).indexOf(val)
  
    if(currentIndex !== -1){
      _city =  citylist[currentIndex].label;
    }
  
    return _city;
  }


  if(loading){
    return (
      
      <Grid style={{ width: "100%",display: "flex", alignItems: "center",  flexDirection: "column"  }}>


      <Grid style={{ width: "100%", marginTop: 60, maxWidth: 1200, display: "flex", padding: 25, height: "80vh",
       flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
        <Box sx={{ display: 'flex' }}>
          <CircularProgress color="inherit" />
        </Box>
      </Grid>
    </Grid>
    );
  }

  if(thankyou){
    return(
      <Grid style={{ width: "100%", display: "flex", alignItems: "center",  flexDirection: "column"  }}>


        <Grid style={{ width: "100%", marginTop: 60, maxWidth: 1200, display: "flex", padding: 25, height: "80vh",
         flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
          <Typography align='center' style={{ fontWeight: 700, fontSize: 36 }}>
          សូមអរគុណចំពោះការចុះឈ្មោះរបស់អ្នក។
          </Typography>
          <Typography align='center' style={{ fontSize: 12, marginTop: 15 }}>
          អ៊ីមែលដែលមានព័ត៌មានលម្អិតអំពីការចុះឈ្មោះរបស់អ្នកត្រូវបានផ្ញើទៅកាន់អាសយដ្ឋានអ៊ីមែលដែលបានកំណត់រួចរាល់។
          </Typography>
          <Typography align='center' style={{ fontSize: 12, marginTop: 5 }}>
          សូមរក្សាវាទុកជាកំណត់ត្រារបស់អ្នក។
          </Typography>

          <Grid style={{ width: "100%", maxWidth: 500, height: 2, borderBottom: '1px solid white', opacity: .5, marginTop: 20, marginBottom: 10  }}/>
          

          <Typography align='center' style={{ fontSize: 12, marginTop: 15 }}>
          យើងប្តេជ្ញាផ្តល់ជូនលោកអ្នកនូវបទពិសោធន៍ដ៏ល្អបំផុតដែលអាចធ្វើទៅបាន។
          </Typography>
          <Typography align='center' style={{ fontSize: 12, marginTop: 5 }}>
          ប្រសិនបើអ្នកមានសំណួរណាមួយ សូមកុំស្ទាក់ស្ទើរក្នុងការទាក់ទងមកយើងខ្ញុំ (+855(0)12 322 085 / +855(0)98 392 062)
          </Typography>
          <Button variant="contained" style={{ marginTop: 20 }} href={`/kh/${currentCountry}-warranty-registration`}   disableElevation>
          Back
          </Button>
        </Grid>
      </Grid>

    )
  }

  return (
    <>
      <Helmet>
        <title> {`ការចុះឈ្មោះធានា`} </title>
      </Helmet>

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"តើអ្នកប្រាកដថាចង់ដាក់ស្នើឬទេ?"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
              <Grid style={{ width: "100%", display: "flex",
            flexDirection: "column" }}>
              <Typography align='left' style={{ fontSize: 14, marginTop: 15 }}>
              {`ឈ្មោះ: ${fullname || ""}`}
              </Typography>
              <Typography align='left' style={{ fontSize: 14, marginTop: 15 }}>
              {`លេខទូរស័ព្ទ: +${mobileNum || ""}`}
              </Typography>
              <Typography align='left' style={{ fontSize: 14, marginTop: 15 }}>
              {`អុីម៉ែល: ${email || ""}`}
              </Typography>
              <Typography align='left' style={{ fontSize: 14, marginTop: 15 }}>
              {`ទីក្រុង: ${renderCity(city) || ""}`}
              </Typography>
              <Typography align='left' style={{ fontSize: 14, marginTop: 15 }}>
              {`ម៉ូដែលទូរស័ព្ទ: ${renderPhoneModel(phoneModel) || ""}`}
              </Typography>
              <Typography align='left' style={{ fontSize: 14, marginTop: 15 }}>
              {`ពណ៌ទូរស័ព្ទ: ${capitalizeFirstLetter(phoneColor) || ""}`}
              </Typography>
              <Typography align='left' style={{ fontSize: 14, marginTop: 15 }}>
              {`ទំហំផ្ទុកទិន្នន័យទូរស័ព្ទ:  ${phoneCapacity ? phoneCapacity.toUpperCase() : ""}`}
              </Typography>
              <Typography align='left' style={{ fontSize: 14, marginTop: 15 }}>
              {`លេខកូដម៉ាស៊ីន: ${serialNumber || ""}`}
              </Typography>
              <Typography align='left' style={{ fontSize: 14, marginTop: 15 }}>
              {`កាលបរិច្ឆេទដំណើរការម៉ាស៊ីន: ${date ? date.$d.toISOString().substring(0, 10) : ""}`}
              </Typography>
              <Typography align='left' style={{ fontSize: 14, marginTop: 15 }}>
              {`ឯកសារភ្ជាប់: ${file ? file.name : ""}`}
              </Typography>
            </Grid>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={saveData} autoFocus>
            Submit
          </Button>
        </DialogActions>
      </Dialog>

      <Grid style={{ width: "100%", display: "flex", alignItems: "center",  flexDirection: "column"  }}>


        <Grid style={{ width: "100%", marginTop: 60, maxWidth: 1200, display: "flex", padding: 25, 
         flexDirection: "column", alignItems: "center" }}>
          <Typography style={{ fontWeight: 700, fontSize: 30 }}>
          {`ការចុះឈ្មោះធានា`}
            </Typography>

            <Box
      component="form"
      sx={{
        '& .MuiTextField-root': { m: 1, width: '30ch' },
      }}
      noValidate
      autoComplete="off"
      onSubmit={submitHandler}
    >
      <Typography style={{ fontWeight: 700, fontSize: 18, marginTop: 30 }}>
      ព័ត៌មានផ្ទាល់ខ្លួន
            </Typography>
      <Grid >
        <Grid container style={{ width: "100%"}} spacing={2}>

          <Grid item xs={12} md={6}>
            <TextField
            value={fullname}
            onChange={handleChangeFullname}
            style={{ margin: 0, marginTop: 15,width: "100%" }}
              required
              error={fullnameError}
              id="outlined-required"
              label="ឈ្មោះ"
              type='text'
            />
            <Typography style={{ fontSize: 10, marginTop: 5, paddingLeft: 10 }}>e.g. Sinn Sisamouth</Typography>
          </Grid>

            <Grid item xs={12} md={6}>
              <PhoneInput
              
             
                country={'kh'}
                style={{ margin: 0, marginTop: 15, width: "100%" }}
                value={mobileNum}
                onChange={handleChangeMobileNum}
              />
              <Typography style={{ fontSize: 10, marginTop: 5, paddingLeft: 10 }}>e.g. +855 23 456 789   </Typography>
              {mobileNumError ? <Typography style={{  fontSize: 11, color: 'red' }}>
              លេខទូរស័ព្ទមិនត្រឹមត្រូវ
          </Typography> : null }
            </Grid>

            <Grid item xs={12} md={6}>
              <TextField
                style={{ margin: 0, marginTop: 15, width: "100%" }}
                value={email}
                onChange={handleChangeEmail}
                autoComplete='off'
                id="outlined-required"
                label="អុីម៉ែល"
              />
              <Typography style={{ fontSize: 10, marginTop: 5, paddingLeft: 10 }}>e.g. abc@gmail.com </Typography>
            </Grid>

            <Grid item xs={12} md={6} >
              <FormControl style={{ margin: 0, marginTop: 15, width: "100%" }} fullWidth>
                <InputLabel id="city-select-label">ទីក្រុង</InputLabel>
                <Select
                  labelId="city-select-label"
                  id="city-select"
                  value={city}
                  error={cityError}
                  label="ទីក្រុង"
                  onChange={handleChangeCity}
                >
                  {citylist.map(val=>{
                    return  <MenuItem value={val.id}>{val.label}</MenuItem>
                  })}
                
                  
                </Select>
              </FormControl>
            </Grid>

        </Grid>
        
        
        
      </Grid>
      <Typography style={{ fontWeight: 700, fontSize: 18, marginTop: 30 }}>
      បញ្ចូលព័ត៌មានទូរស័ព្ទ
            </Typography>
      <Grid container style={{ width: "100%"}} spacing={2}>
       
        <Grid item xs={12} md={6} >
          <TextField
         style={{ margin: 0, marginTop: 15, width: "100%" }}
          required
              id="outlined-helperText"
              label="លេខកូដម៉ាស៊ីន"
              inputProps={{ style: { textTransform: "uppercase" } }}
              value={serialNumber}
              error={(serialNumberError)}
              onChange={handleChangeSerialNumber}
            />
             <Typography style={{ fontSize: 10, marginTop: 5, paddingLeft: 10 }}>e.g. FFWDNZ8E0F</Typography>
            {serialNoRegistered ? <Typography style={{ marginTop: 10 , fontSize: 11, color: 'red' }}>
            លេខ serial ធ្លាប់បានចុះឈ្មោះរួចហើយ
          </Typography> : null}
          {serialNumberError ? <Typography style={{ fontSize: 11, color: 'red' }}>
          លេខ serial មិនត្រឹមត្រូវ
          </Typography> : null }
          
        </Grid>

        

        <Grid item xs={12} md={6} style={{ paddingLeft: 7, paddingTop: 25 }}>
          <LocalizationProvider sx={{ width: "100%"}}  dateAdapter={AdapterDayjs}>
            <DatePicker 
              label="កាលបរិច្ឆេទដំណើរការម៉ាស៊ីន"
              value={date}
              onChange={(newDate) => setDate(newDate)}
              sx={{ width: "100%"}}  />
          </LocalizationProvider>
        </Grid>

        <Grid item xs={12} md={4} style={{ paddingLeft: 16, paddingTop: 25 }}>
        <Typography style={{ fontSize: 10, marginTop: 5, paddingLeft: 10 }}>ម៉ូដែលទូរស័ព្ទ</Typography>
          <FormControl fullWidth>
            <Select
              labelId="phone-model-select-label"
              id="phone-model-select"
              disabled
              error={phoneModelerror}
              value={phoneModel}
              onChange={handleChangePhoneModel}
            >
              <MenuItem value={"iphone 16"}>iPhone 16</MenuItem>
              <MenuItem value={"iphone 16 plus"}>iPhone 16 Plus</MenuItem>
              <MenuItem value={"iphone 16 pro"}>iPhone 16 Pro</MenuItem>
              <MenuItem value={"iphone 16 pro max"}>iPhone 16 Pro Max</MenuItem>
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={12} md={4} style={{ paddingLeft: 16, paddingTop: 25 }}>
        <Typography style={{ fontSize: 10, marginTop: 5, paddingLeft: 10 }}>ពណ៌ទូរស័ព្ទ</Typography>
          <FormControl fullWidth>
            <Select
              labelId="phone-color-select-label"
              id="phone-color-select"
              disabled
              value={phoneColor}
              onChange={handleChangePhoneColor}
            >
              {phonecolorlistiphone16all.map(val=>{
                return <MenuItem value={val.id}>{val.label}</MenuItem>
              })}
            </Select>
          </FormControl>
        </Grid>
        
        <Grid item xs={12} md={4} style={{ paddingLeft: 16, paddingTop: 25 }}>
        <Typography style={{ fontSize: 10, marginTop: 5, paddingLeft: 10 }}>ទំហំផ្ទុកទិន្នន័យទូរស័ព្ទ</Typography>
          <FormControl fullWidth>
            <Select
              labelId="phone-capacity-select-label"
              id="phone-capacity-select"
              disabled
              value={phoneCapacity}
              onChange={handleChangePhoneCapacity}
            >
              {phonecapacitylistiphone16all.map(val=>{
                return <MenuItem value={val.id}>{val.label}</MenuItem>
              })}
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={12} md={12} style={{ paddingLeft: 7, paddingTop: 25 }}>
            <MuiFileInput color='primary'
            required
            style={{ width: "100%"}}
            error={fileInputError}
            placeholder="បញ្ចូលប័ណ្ណធានារបស់Falcon*"
            getInputText={(value) => value ? value.name : 'បញ្ចូលប័ណ្ណធានារបស់Falcon*'}
            inputProps={{ accept: 'image/jpeg,image/png,application/pdf' }}
            value={file} onChange={handleOnChangeUploadfile} />
        </Grid>

      </Grid>

      <Grid style={{ width: "100%", display: "flex", flexDirection: "row", marginTop: 30  }}>
      <FormGroup>
        <FormControlLabel required control={<Checkbox onChange={handleChangeAgreement} checked={agreement}  />} label={
          <Grid style={{ display: "flex", flexDirection: "column" }}>
            <Typography style={{ fontWeight: 700, fontSize: 14 }}>
              {`ខ្ញុំបានអាន និងព្រមព្រៀងតាមលក្ខខណ្ឌ (${termName})`}
            </Typography>
            <Link href="/kh/terms-and-conditions-iphone16-series" target='_blank'>
        <Typography style={{ fontWeight: 700, fontSize: 14 }}>
        គោលការណ៍ និងលក្ខខណ្ឌ *
            </Typography>

            {agreement ? null : <Typography style={{ fontWeight: 100, fontSize: 10, color: 'red' }}>
            ចំណុចចាំបាច់ត្រូវបំពេញ
            </Typography> }

      </Link>
          </Grid>} />
        
      </FormGroup>
      
      
      </Grid>
      <Button disabled={!agreement} type="submit" variant="contained" style={{ marginTop: 30 }}  disableElevation>
      ដាក់ស្នើរការចុះឈ្មោះ
          </Button>
    </Box>


            
        </Grid>

       
        
      </Grid>
    </>
  );
}
