import { Helmet } from 'react-helmet-async';
import { filter } from 'lodash';
import { sentenceCase } from 'change-case';
import * as FileSaver from 'file-saver';
import * as React from 'react';
import XLSX from 'sheetjs-style';
import PropTypes from 'prop-types';
import { useTheme } from '@mui/material/styles';
import moment from 'moment'
// @mui
import {
  Card,
  Table,
  Stack,
  Paper,
  Avatar,
  Button,
  Popover,
  Checkbox,
  TableRow,
  MenuItem,
  TableBody,
  TableCell,
  Container,
  Typography,
  Tooltip,
  IconButton,
  TableContainer,
  TablePagination,
  Box,
  Grid
} from '@mui/material';

import Link from '@mui/material/Link';

import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';
import dayjs from 'dayjs';

import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

import { useNavigate } from 'react-router-dom';
import { useState, useEffect } from 'react';
import CircularProgress from '@mui/material/CircularProgress';

import { collection, getDocs, getFirestore, addDoc,updateDoc , doc, 
  serverTimestamp, onSnapshot, query, orderBy, limitToLast, Timestamp, writeBatch } from "firebase/firestore";
import { getDatabase, ref as dbref, runTransaction, set, child, get, update } from "firebase/database";
import { initializeApp } from "firebase/app";
import { getStorage, ref, getDownloadURL } from "firebase/storage";

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';


// components
import Label from '../components/label';
import Iconify from '../components/iconify';
import Scrollbar from '../components/scrollbar';
// sections
import { UserListHead, UserListToolbar } from '../sections/@dashboard/claim';

import { UserAuth } from '../context/AuthContext'

const firebaseConfig = {
  apiKey: "AIzaSyAVgHwu_fzmXe1vQzrPFUPBP4pnx2Zr8IQ",
  authDomain: "azteq-dc1d6.firebaseapp.com",
  databaseURL: "https://azteq-dc1d6-default-rtdb.firebaseio.com",
  projectId: "azteq-dc1d6",
  storageBucket: "azteq-dc1d6.firebasestorage.app",
  messagingSenderId: "747452880628",
  appId: "1:747452880628:web:9b5d8dbf3cfc0bf7f630a1"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);


// Initialize Cloud Firestore and get a reference to the service
const db = getFirestore(app);

const rtdb = getDatabase(app);

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'id', label: 'Claim ID', alignRight: false },
  { id: 'created', label: 'Created Date/Time', alignRight: false },
  { id: 'purchase_date', label: 'Activation Date', alignRight: false },
  { id: 'status', label: 'Status', alignRight: false },
  { id: 'customer_name', label: 'Full Name', alignRight: false },
  { id: 'serialno', label: 'Serial No', alignRight: false },
  { id: 'customer_phone', label: 'Mobile Number', alignRight: false },
  { id: '' },
];

// ----------------------------------------------------------------------

function TablePaginationActions(props) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

function descendingComparator(a, b, orderBy2) {
  if (b[orderBy2] < a[orderBy2]) {
    return -1;
  }
  if (b[orderBy2] > a[orderBy2]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy2) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy2)
    : (a, b) => -descendingComparator(a, b, orderBy2);
}

function applySortFilter(array, comparator, query, select) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {

    if(select === "customer_name"){
      return filter(array, (_user) => _user.customer_name.toLowerCase().indexOf(query.toLowerCase()) !== -1);
    }
    
    if(select === "id"){
      return filter(array, (_user) => _user.id.toLowerCase().indexOf(query.toLowerCase()) !== -1);
    }

    if(select === "customer_email"){
      return filter(array, (_user) => _user.customer_email.toLowerCase().indexOf(query.toLowerCase()) !== -1);
    }

    if(select === "serialno"){
      return filter(array, (_user) => _user.serialno.toLowerCase().indexOf(query.toLowerCase()) !== -1);
    }

    if(select === "customer_phone"){
      return filter(array, (_user) => _user.customer_phone.toLowerCase().indexOf(query.toLowerCase()) !== -1);
    }
  }
  return stabilizedThis.map((el) => el[0]);
}

const fileType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
const fileExtension = '.xlsx';

export default function ProductPage() {

  const { user } = UserAuth()

  const [open, setOpen] = useState(null);

  const [page, setPage] = useState(0);

  const [order, setOrder] = useState('desc');

  const [selected, setSelected] = useState([]);

  const [orderBy2, setOrderBy2] = useState('created');

  const [filterName, setFilterName] = useState('');

  const [rowsPerPage, setRowsPerPage] = useState(5);

  const [row, setRow] = useState([]);

  const [fileUrl, setFileUrl] = useState(null);

  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);

  const [selectedStatus, setSelectedStatus] = useState('');
  const [userRole, setUserRole] = useState('super_admin');
  
  const [startDate, setStartDate] = useState(dayjs(new Date()));
  const [endDate, setEndDate] = useState(dayjs(new Date()));

  const handleChangeStatus = (event) => {
    setSelectedStatus(event.target.value);
  };

  const [select, setSelect] = useState('id');

  const handleChangeSelect = (event) => {
    setSelect(event.target.value);
  };


  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogOpen2, setDialogOpen2] = useState(false);
  const [dialogOpen3, setDialogOpen3] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [openDetails, setOpenDetails] = useState(false);
  const [dialogLoading, setDialogLoading] = useState(false);

  const handleCloseDetails = () => {
    setOpenDetails(false);
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
  };

  const handleDialogClose2 = () => {
    setDialogOpen2(false);
  };

  const handleDialogClose3 = () => {
    setDialogOpen3(false);
  };


  const handleDialogSubmit = () => {

    update(dbref(rtdb, `sc_claimed/${selectedItem.id}`), {
      status: selectedStatus
    })
    
    setDialogOpen(false); 
  };

  useEffect(() => {


    let _user = "";
    if(user && user.reloadUserInfo && user.reloadUserInfo.customAttributes){
      _user = JSON.parse(user.reloadUserInfo.customAttributes);
    }
    setUserRole(_user.role)

     const arr = [];
      get(child(dbref(rtdb), `sc_claimed`)).then((snapshot) => {
        if (snapshot.exists()) {

          snapshot.forEach((doc) => {

            arr.push({
              ...doc.val(),
            });
            
            })


          setRow(arr);
          setLoading(false);

        } else {

          setRow([]);
          setLoading(false);
          console.log("No data available");
        }
      }).catch((error) => {
        console.error(error);
      }); 

   
  }, [])


  const handleSubmitDownload = async () => {

    setDialogLoading(true);

    const _newRow = []
    row.forEach(val => {

        const _item = {};
        _item["Registration ID"] = val.id;
        _item["Registration Date/Time"] = `${new Date(val.created*1000).getFullYear()}-${new Date(val.created*1000).getMonth()+1}-${new Date(val.created*1000).getDate()} (${new Date(val.created*1000).getHours()}: ${new Date(val.created*1000).getMinutes()})`;
        _item["Activation Date"] = val.purchase_date;
        _item.Owner = val.full_name;
        _item["Email Address"] = val.email;
        
        _item["Serial No."] = val.serialno;
        _item.Country = val.country;
        _item.City = val.city;
        _item["Phone Model"] = val.phone_model;
        _item["Phone Color"] = val.phone_color;
        _item["Phone Capacity"] = val.phone_capacity;
        _item.Warranty = "Screen Replacement Warranty";
  
        _newRow.push(_item)
      
    })


    const ws = XLSX.utils.json_to_sheet(_newRow);
    const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, `device_registrations_data${fileExtension}`); 

    setDialogOpen2(false);
    setDialogLoading(false); 

  }

  const handleSubmitDownloadCreatedDate = async () => {

    setDialogLoading(true);

    const _newRow = []


    row.forEach(val => {

      if(moment.unix(val.created).isSameOrAfter(startDate.format('YYYY-MM-DD')) 
      && moment.unix(val.created).isSameOrBefore(endDate.format('YYYY-MM-DD')))
      {
        const _item = {};
        _item["Registration ID"] = val.id;
        _item["Registration Date/Time"] = `${new Date(val.created*1000).getFullYear()}-${new Date(val.created*1000).getMonth()+1}-${new Date(val.created*1000).getDate()} (${new Date(val.created*1000).getHours()}: ${new Date(val.created*1000).getMinutes()})`;
        _item["Activation Date"] = val.purchase_date;
        _item.Owner = val.full_name;
        _item["Email Address"] = val.email;
        
        _item["Serial No."] = val.serialno;
        _item.Country = val.country;
        _item.City = val.city;
        _item["Phone Model"] = val.phone_model;
        _item["Phone Color"] = val.phone_color;
        _item["Phone Capacity"] = val.phone_capacity;
        _item.Warranty = "Screen Replacement Warranty";
 
 
        _newRow.push(_item)
      }
      
    })


    const ws = XLSX.utils.json_to_sheet(_newRow);
    const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, `claim_submission_data_created_date${fileExtension}`); 

    setDialogOpen3(false);
    setDialogLoading(false);

  }

  const downloadExcel = async () => {
    setDialogOpen2(true);
  }

  const downloadExcelCreatedDate = async () => {
    setDialogOpen3(true);
  }

  const handleOnDetails = () => {
    setOpenDetails(true);
    setOpen(false)
  }

  const handleOnEdit = () => {
    setDialogOpen(true);
    setOpen(false)
  }

  const handleOpenMenu = (event, row) => {
    setSelectedItem(row)
    setOpen(event.currentTarget);
    setSelectedStatus(row.status)
  };

  const handleCloseMenu = () => {
    setOpen(null);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy2 === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy2(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = row.map((n) => n.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const handleFilterByName = (event) => {
    setPage(0);
    setFilterName(event.target.value);
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - row.length) : 0;

  const filteredUsers = applySortFilter(row, getComparator(order, orderBy2), filterName, select);

  const isNotFound = !filteredUsers.length && !!filterName;

  if(loading){
    return (
      
      <Grid style={{ width: "100%", display: "flex", alignItems: "center",  flexDirection: "column"  }}>


      <Grid style={{ width: "100%", marginTop: 60, maxWidth: 1200, display: "flex", padding: 25, height: "80vh",
       flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
        <Box sx={{ display: 'flex' }}>
          <CircularProgress color="inherit" />
        </Box>
      </Grid>
    </Grid>
    );
  }


  const renderFilePath = (path) => {
    
    const printUrl = async (_path) => {
      const storage = getStorage(app);
      const _url = await getDownloadURL(ref(storage, _path))
        .then((url) => {
          return url;
        })
        .catch((error) => {
          // Handle any errors
        });
        setFileUrl(_url)
    };
    
    printUrl(path);

    return fileUrl;
  }

  

  return (
    <>
      <Helmet>
        <title> Claim Submission | Falcon </title>
      </Helmet>

      <Dialog
        open={openDetails}
        onClose={handleCloseDetails}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Registration Details"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
              {selectedItem ? <Grid style={{ width: "100%", display: "flex",
                flexDirection: "row" }} ><Grid style={{ width: "100%", display: "flex",
                flexDirection: "column", paddingRight: 30 }}>

                  <Typography align='left' style={{ fontSize: 14, marginTop: 15 }}>
                  Claim ID
                  </Typography>
                  <Typography align='left' style={{ fontWeight: 700, fontSize: 16 }}>
                  {selectedItem && selectedItem.id ? selectedItem.id : "-"}
                  </Typography>

                  <Typography align='left' style={{ fontSize: 14, marginTop: 15 }}>
                  Created Date/Time
                  </Typography>
                  <Typography align='left' style={{ fontWeight: 700, fontSize: 14 }}>{`${new Date(selectedItem.created*1000).getFullYear()}
                -${new Date(selectedItem.created*1000).getMonth()+1}
  -${new Date(selectedItem.created*1000).getDate()} ${new Date(selectedItem.created*1000).getHours()}: ${new Date(selectedItem.created*1000).getMinutes()}`}
                  </Typography>

                  <Typography align='left' style={{ fontSize: 14, marginTop: 15 }}>
                  Activation Date
                  </Typography>
                  <Typography align='left' style={{ fontWeight: 700, fontSize: 16 }}>
                  {selectedItem.purchase_date}
                  </Typography>

                  <Typography align='left' style={{ fontSize: 14, marginTop: 15 }}>
                  Full Name
                  </Typography>
                  <Typography align='left' style={{ fontWeight: 700, fontSize: 16 }}>
                  {selectedItem.customer_name}
                  </Typography>

                  <Typography align='left' style={{ fontSize: 14, marginTop: 15 }}>
                 Phone Model
                  </Typography>
                  <Typography align='left' style={{ fontWeight: 700, fontSize: 16 }}>
                  {selectedItem.phone_model}
                  </Typography>

                  <Typography align='left' style={{ fontSize: 14, marginTop: 15 }}>
                 Phone Color
                  </Typography>
                  <Typography align='left' style={{ fontWeight: 700, fontSize: 16 }}>
                  {selectedItem.phone_color}
                  </Typography>

                  <Typography align='left' style={{ fontSize: 14, marginTop: 15 }}>
                 Phone Capacity
                  </Typography>
                  <Typography align='left' style={{ fontWeight: 700, fontSize: 16 }}>
                  {selectedItem.phone_capacity}
                  </Typography>


              </Grid>
              <Grid style={{ width: "100%", display: "flex",
                flexDirection: "column" }}>

<Typography align='left' style={{ fontSize: 14, marginTop: 15 }}>
                  Email
                  </Typography>
                  <Typography align='left' style={{ fontWeight: 700, fontSize: 16 }}>
                  {selectedItem.customer_email}
                  </Typography>

                  <Typography align='left' style={{ fontSize: 14, marginTop: 15 }}>
                  City
                  </Typography>
                  <Typography align='left' style={{ fontWeight: 700, fontSize: 16 }}>
                  {selectedItem.city}
                  </Typography>

                  <Typography align='left' style={{ fontSize: 14, marginTop: 15 }}>
                  Serial No.
                  </Typography>
                  <Typography align='left' style={{ fontWeight: 700, fontSize: 16 }}>
                  {selectedItem.serialno}
                  </Typography>

                  <Typography align='left' style={{ fontSize: 14, marginTop: 15 }}>
                  Country
                  </Typography>
                  <Typography align='left' style={{ fontWeight: 700, fontSize: 16 }}>
                  {selectedItem.country}
                  </Typography>


                  <Typography align='left' style={{ fontSize: 14, marginTop: 15 }}>
                  Status
                  </Typography>
                  <Typography align='left' style={{ fontWeight: 700, fontSize: 16 }}>
                  {selectedItem.status}
                  </Typography>


              </Grid>
              </Grid> : null }
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDetails}>Close</Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={dialogOpen2}
        onClose={handleDialogClose2}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Download Excel"}
        </DialogTitle>
        {dialogLoading ? <DialogContent>
          <CircularProgress color="inherit" />
          </DialogContent> : <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Please select the activation date range
          </DialogContentText>
          <Grid style={{ width: '100%' }}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DemoContainer components={['DatePicker']}>
              <DatePicker label="Start Date"
              value={startDate}
              onChange={(newValue) => setStartDate(newValue)}/>
            </DemoContainer>
          </LocalizationProvider>

          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DemoContainer components={['DatePicker']}>
              <DatePicker label="End Date" 
              value={endDate}
              onChange={(newValue) => setEndDate(newValue)}/>
            </DemoContainer>
          </LocalizationProvider>
          </Grid>
        </DialogContent>}
        {dialogLoading ? null : <DialogActions>
          <Button onClick={handleDialogClose2}>Cancel</Button>
          <Button onClick={handleSubmitDownload} autoFocus>
            Download
          </Button>
        </DialogActions> }
      </Dialog>

      <Dialog
        open={dialogOpen3}
        onClose={handleDialogClose3}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Download Excel (Created Date)"}
        </DialogTitle>
        {dialogLoading ? <DialogContent>
          <CircularProgress color="inherit" />
          </DialogContent> : <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Please select the created date range
          </DialogContentText>
          <Grid style={{ width: '100%' }}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DemoContainer components={['DatePicker']}>
              <DatePicker label="Start Date"
              value={startDate}
              onChange={(newValue) => setStartDate(newValue)}/>
            </DemoContainer>
          </LocalizationProvider>

          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DemoContainer components={['DatePicker']}>
              <DatePicker label="End Date" 
              value={endDate}
              onChange={(newValue) => setEndDate(newValue)}/>
            </DemoContainer>
          </LocalizationProvider>
          </Grid>
        </DialogContent>}
        {dialogLoading ? null : <DialogActions>
          <Button onClick={handleDialogClose3}>Cancel</Button>
          <Button onClick={handleSubmitDownloadCreatedDate} autoFocus>
            Download
          </Button>
        </DialogActions> }
      </Dialog>

      <Dialog
        open={dialogOpen}
        onClose={handleDialogClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Edit"}
        </DialogTitle>
        <DialogContent>
          <FormControl style={{ marginTop: 20 }} fullWidth>
            <InputLabel id="demo-simple-select-label">Status</InputLabel>
            <Select
              labelId="Status"
              id="Status"
              value={selectedStatus}
              label="Status"
              onChange={handleChangeStatus}
            >
              <MenuItem value={"submit_for_approval"}>Submit For Approval</MenuItem>
              <MenuItem value={"seek_verification"}>Seek Verification</MenuItem>
              <MenuItem value={"submit_for_repair_detail"}>Submitted Repair Detail</MenuItem>
              <MenuItem value={"seek_verification_repair_detail"}>Seek Verification Repair Detail</MenuItem>
              <MenuItem value={"approved"}>Approved</MenuItem>
              <MenuItem value={"cancelled"}>Cancelled</MenuItem>
              <MenuItem value={"rejected"}>Rejected</MenuItem>
              <MenuItem value={"closed"}>Closed</MenuItem>
            </Select>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose}>No</Button>
          <Button onClick={handleDialogSubmit} autoFocus>
            Yes
          </Button>
        </DialogActions>
      </Dialog>

      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
          Claim Submission
          </Typography>
          <Stack direction="row" alignItems="end" >
            {/* <Button variant="contained" style={{ marginRight: 10 }} onClick={downloadExcel}>
              {"Download Excel (Activation Date)"}
            </Button>
            <Button variant="contained" style={{ marginRight: 10 }} onClick={downloadExcelCreatedDate}>
              {"Download Excel (Created Date)"}
            </Button> */}
           {/* } {userRole === "super_admin" ? <Button variant="contained"  href='/dashboard/bulk_upload_device'>
              Bulk Upload
            </Button> : null} */}
           
          </Stack>
          
        </Stack>

        <Card>
          <UserListToolbar 
          handleChangeSelect={handleChangeSelect}
          select={select}
          numSelected={selected.length} 
          filterName={filterName} 
          onFilterName={handleFilterByName} />

          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <UserListHead
                  order={order}
                  orderBy={orderBy2}
                  headLabel={TABLE_HEAD}
                  rowCount={row.length}
                  numSelected={selected.length}
                  onRequestSort={handleRequestSort}
                  onSelectAllClick={handleSelectAllClick}
                />
                <TableBody>
                  {filteredUsers.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                    const { id } = row;
                    const selectedUser = selected.indexOf(id) !== -1;

                    return (
                      <TableRow hover key={id} tabIndex={-1} role="checkbox" selected={selectedUser}>
                        <TableCell padding="checkbox">
                          <Checkbox checked={selectedUser} onChange={(event) => handleClick(event, id)} />
                        </TableCell>

                        <TableCell component="th" scope="row" >
                          <Stack direction="row" alignItems="center" spacing={2}>
                            <Typography variant="subtitle2" noWrap>
                              {id}
                            </Typography>
                          </Stack>
                        </TableCell>

                        <TableCell align="left">
                          <Typography align='left' style={{ fontSize: 14 }}>{`${new Date(row.created*1000).getFullYear()}
                        -${new Date(row.created*1000).getMonth()+1}
                        -${new Date(row.created*1000).getDate()}`}
                          </Typography>
                          <Typography align='left' style={{ fontSize: 14 }}>
                            {`${new Date(row.created*1000).getHours()}: ${new Date(row.created*1000).getMinutes()}`}
                          </Typography>
                        </TableCell>

                        <TableCell align="left">{row.purchase_date}</TableCell>

                        <TableCell align="left">{row.status}</TableCell>

                        <TableCell align="left">{row.customer_name}</TableCell>
                        
                        <TableCell align="left">{row.serialno}</TableCell>

                        <TableCell align="left">{row.customer_phone}</TableCell>

                        <TableCell align="right">
                          <IconButton size="large" color="inherit" onClick={(e)=> handleOpenMenu(e, row)}>
                            <Iconify icon={'eva:more-vertical-fill'}/>
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>

                {isNotFound && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                        <Paper
                          sx={{
                            textAlign: 'center',
                          }}
                        >
                          <Typography variant="h6" paragraph>
                            Not found
                          </Typography>

                          <Typography variant="body2">
                            No results found for &nbsp;
                            <strong>&quot;{filterName}&quot;</strong>.
                            <br /> Try checking for typos or using complete words.
                          </Typography>
                        </Paper>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}

                
              </Table>
            </TableContainer>
          </Scrollbar>

          <TablePagination
            rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
            component="div"
            count={row.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            ActionsComponent={TablePaginationActions}
          />
        </Card>
      </Container>

      <Popover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleCloseMenu}
        anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        PaperProps={{
          sx: {
            p: 1,
            width: 140,
            '& .MuiMenuItem-root': {
              px: 1,
              typography: 'body2',
              borderRadius: 0.75,
            },
          },
        }}
      >

          <MenuItem onClick={handleOnDetails} >
            <Iconify icon={'clarity:details-solid'} sx={{ mr: 2 }} />
            Details
          </MenuItem>

          <MenuItem onClick={handleOnEdit} >
            <Iconify icon={'eva:edit-fill'} sx={{ mr: 2 }} />
            Edit
          </MenuItem>

      </Popover>
    </>
  );
}
