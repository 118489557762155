import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import PersonAddAltIcon from '@mui/icons-material/PersonAddAlt';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import CircularProgress from '@mui/material/CircularProgress';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { initializeApp } from "firebase/app";
import { getFirestore, collection, addDoc, serverTimestamp, setDoc, doc, Timestamp, getDocs, query, where   } from "firebase/firestore";
import { getAuth, createUserWithEmailAndPassword } from "firebase/auth";
import { IconButton, InputAdornment } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import Iconify from '../components/iconify';
import { UserAuth } from '../context/AuthContext'

// TODO: Replace the following with your app's Firebase project configuration
// See: https://support.google.com/firebase/answer/7015592
const firebaseConfig = {
  apiKey: "AIzaSyAVgHwu_fzmXe1vQzrPFUPBP4pnx2Zr8IQ",
  authDomain: "azteq-dc1d6.firebaseapp.com",
  databaseURL: "https://azteq-dc1d6-default-rtdb.firebaseio.com",
  projectId: "azteq-dc1d6",
  storageBucket: "azteq-dc1d6.firebasestorage.app",
  messagingSenderId: "747452880628",
  appId: "1:747452880628:web:9b5d8dbf3cfc0bf7f630a1"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);


// Initialize Cloud Firestore and get a reference to the service
const db = getFirestore(app);

// TODO remove, this demo shouldn't need to reset the theme.
export default function NewUserPage() {

  const { user } = UserAuth()

  const [role, setRole] = React.useState('editor');
  const [country, setCountry] = React.useState('cambodia');
  const [status, setStatus] = React.useState('active');
  const [showPassword, setShowPassword] = React.useState(false);
  const [showRePassword, setShowRePassword] = React.useState(false);
  
  const [firstNameError, setFirstNameError] = React.useState(false);
  const [lastNameError, setLastNameError] = React.useState(false);
  const [emailError, setEmailError] = React.useState(false);
  const [passwordError, setPasswordError] = React.useState(false);
  const [repasswordError, setRePasswordError] = React.useState(false);

  const [loading, setLoading] = React.useState(false);

  const navigate = useNavigate();

  

  const handleChangeRole = (event) => {
    setRole(event.target.value);
  };

  const handleChangeCountry = (event) => {
    setCountry(event.target.value);
  };

  const handleChangeStatus = (event) => {
    setStatus(event.target.value);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    let emailPass = false;
    let passwordPass = false;
    let repasswordPass = false;
    let firstNamePass = false;
    let lastNamePass = false;

    const data = new FormData(event.currentTarget);

    const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
    if(data.get('email').length > 0 && emailRegex.test(data.get('email'))){
      setEmailError(false)
      emailPass = true;
    }
    else
    {
      setEmailError(true)
    }

    if(data.get('password').length > 6){
      setPasswordError(false)
      passwordPass = true;
    }
    else
    {
      setPasswordError(true)
    }

    if(data.get('re-password') === data.get('password')){
      setRePasswordError(false)
      repasswordPass = true;
    }
    else
    {
      setRePasswordError(true)
    }

    if(data.get('firstName').length > 1){
      setFirstNameError(false)
      firstNamePass = true;
    }
    else
    {
      setFirstNameError(true)
    }

    if(data.get('lastName').length > 1){
      setLastNameError(false)
      lastNamePass = true;
    }
    else
    {
      setLastNameError(true)
    }
   
    if(emailPass === true && 
      passwordPass === true && 
      firstNamePass === true && 
      repasswordPass === true && 
      lastNamePass === true)
    {

      let _user = "";
      if(user && user.reloadUserInfo && user.reloadUserInfo.customAttributes){
        _user = JSON.parse(user.reloadUserInfo.customAttributes);
      }

      const UID = `UID${Timestamp.fromDate(new Date()).toMillis()}`;
      

        const q = query(collection(db, "users"), where("email", "==", data.get('email')));

        const querySnapshot = await getDocs(q);

        if(querySnapshot.empty)
        {
          setDoc(doc(db, "users", UID), {
            id: UID,
            first_name: data.get('firstName') || null,
            last_name: data.get('lastName') || null,
            password: data.get('password') || null,
            email: data.get('email') || null,
            company: data.get('company') || null,
            role: role || null,
            country: _user.role === "super_admin" ? (role === "super_admin" ? null : country) : _user.country,
            status: role === "super_admin" ? null : status,
            created: serverTimestamp(),
          }).then(()=>{
            setLoading(false);
            navigate('/dashboard/user')
          }).catch((e)=>{
            // console.log(e)
          })
        }
        else
        {
          setEmailError(true)
          setLoading(false);
        }

    }


  };

  let _user = "";
  if(user && user.reloadUserInfo && user.reloadUserInfo.customAttributes){
    _user = JSON.parse(user.reloadUserInfo.customAttributes);
  }

  if(loading){
    return (
      
      <Grid style={{ width: "100%", backgroundColor: 'black', display: "flex", alignItems: "center",  flexDirection: "column"  }}>
        <Grid style={{ width: "100%", marginTop: 60, maxWidth: 1200, display: "flex", padding: 25, height: "80vh",
        flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
          <Box sx={{ display: 'flex' }}>
            <CircularProgress color="inherit" />
          </Box>
        </Grid>
      </Grid>
    );
  }

  return (
    <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
            <PersonAddAltIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            New User
          </Typography>
          <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 3 }}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <TextField
                  autoComplete="given-name"
                  name="firstName"
                  required
                  error={firstNameError}
                  fullWidth
                  id="firstName"
                  label="First Name"
                  autoFocus
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  required
                  fullWidth
                  error={lastNameError}
                  id="lastName"
                  label="Last Name"
                  name="lastName"
                  autoComplete="family-name"
                />
              </Grid>
              <Grid item xs={12} sm={12}>
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">Role</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={role}
                    label="Role"
                    onChange={handleChangeRole}
                  >
                    
                    <MenuItem value={"editor"}>Service Center Associate</MenuItem>
                    <MenuItem value={"manager"}>Service Center Manager</MenuItem>
                    {_user.role === "super_admin" || _user.role === "admin" ? <MenuItem value={"admin"}>Admin</MenuItem> : null}
                    {_user.role === "super_admin" ? <MenuItem value={"super_admin"}>Super Admin</MenuItem> : null}
                  </Select>
                </FormControl>
              </Grid>
              {role === "super_admin" || _user.role !== "super_admin" ? null : <Grid item xs={12} sm={12}>
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">Country</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={_user.role === "super_admin" ? country : _user.country}
                    label="Country"
                    onChange={handleChangeCountry}
                  >
                    
                    <MenuItem value={"cambodia"}>Cambodia</MenuItem>
                  </Select>
                </FormControl>
              </Grid>}
              <Grid item xs={12} sm={12}>
                <TextField
                  fullWidth
                  id="company"
                  label="Company"
                  name="company"
                  autoComplete="company-name"
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  id="email"
                  error={emailError}
                  label="Email Address"
                  name="email"
                  autoComplete="email"
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  error={passwordError}
                  name="password"
                  label="New Password"
                  type={showPassword ? 'text' : 'password'}
                  id="password"
                  autoComplete="new-password"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                          <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  error={repasswordError}
                  name="re-password"
                  label="Confirm New Password"
                  type={showRePassword ? 'text' : 'password'}
                  id="re-password"
                  autoComplete="new-password"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton onClick={() => setShowRePassword(!showRePassword)} edge="end">
                          <Iconify icon={showRePassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
            </Grid>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              Add User
            </Button>

          </Box>
        </Box>

      </Container>
  );
}