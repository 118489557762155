// component
import SvgColor from '../../../components/svg-color';

// ----------------------------------------------------------------------

const icon = (name) => <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />;

const navConfig = [
  {
    title: 'dashboard',
    path: '/dashboard/app',
    icon: icon('ic_analytics'),
  },
  {
    title: 'user',
    path: '/dashboard/user',
    icon: icon('ic_user'),
  },
  {
    title: 'Iphone 16',
    path: '/dashboard/serialno',
    icon: icon('ic_device'),
    submenu: [{
      title: 'Serial No',
      path: '/dashboard/serialno',
      icon: icon('ic_imei'),
    },
    {
      title: 'Registered Device',
      path: '/dashboard/registereddevice',
      icon: icon('ic_device'),
    },
    {
      title: 'Purchased Serial No',
      path: '/dashboard/purchased_serialno',
      icon: icon('ic_imei'),
    }]
  },
  {
    title: 'Search Registered Device',
    path: '/dashboard/search_device',
    icon: icon('ic_search'),
  },
  {
    title: 'Claim Dashboard',
    path: '/dashboard/dashboard_claim',
    icon: icon('claim_dashboard'),
  },
  {
    title: 'Claim',
    path: '/dashboard/search_claim',
    icon: icon('ic_insurance'),
  },
  {
    title: 'Submitted Claim',
    path: '/dashboard/submitted_claim',
    icon: icon('submit_claim'),
  },
  {
    title: 'Download Data',
    path: '/dashboard/download_data',
    icon: icon('ic_download'),
  }
];

export default navConfig;
