import { Outlet } from 'react-router-dom';

import AppAppBar from '../../modules/views/AppAppBarkh';

import AppFooter from '../../modules/views/AppFooter';

export default function Frontend() {
  return (
    <>
      <AppAppBar />
      <Outlet />
      <AppFooter/>
    </>
    
  );
}
