import { Navigate, useRoutes } from 'react-router-dom';

import { Grid } from '@mui/material';
import * as React from 'react';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';

// layouts
import DashboardLayout from './layouts/dashboard';
import SimpleLayout from './layouts/simple';
import Frontend from './layouts/frontend';
import Frontendkh from './layouts/frontendkh';
//
import TermAndConditionsIphone16 from './pages/TermAndConditionsIphone16';
import SearchRegistration from './pages/SearchRegistration';
import SearchRegistrationkh from './pages/SearchRegistrationkh';
import CambodiaRegistration from './pages/CambodiaRegistration';
import CambodiaRegistrationkh from './pages/CambodiaRegistrationkh';


import TermAndConditions from './pages/TermAndConditions';
import TermAndConditionskh from './pages/TermAndConditionskh';
import Page404 from './pages/Page404';
import HomePage from './pages/HomePage';
import HomePagekh from './pages/HomePagekh';

import SerialNoPage from './pages/SerialNoPage';
import PurchasedImeiPage from './pages/PurchasedImeiPage';
import DevicePage from './pages/DevicePage';
import SubmittedClaimPage from './pages/SubmittedClaimPage';

import AllDevicePage from './pages/AllDevicePage';
import DownloadDataPage from './pages/DownloadDataPage';
import SearchDevicePage from './pages/SearchDevicePage';
import UserPage from './pages/UserPage';
import LoginPage from './pages/LoginPage';
import ForgotPassword from './pages/ForgotPassword';
import DashboardAppPage from './pages/DashboardAppPage';
import DashboardClaimPage from './pages/DashboardClaimPage';

import NewUserPage from './pages/NewUserPage';
import NewPurchasedImei from './pages/NewPurchasedImei';
import NewDeviceRegistrationPage from './pages/NewDeviceRegistrationPage';

import BulkUploadImeiPage from './pages/BulkUploadImeiPage';
import BulkUploadSerialNumberPage from './pages/BulkUploadSerialNumberPage';
import BulkUploadDevicePage from './pages/BulkUploadDevicePage';

import SearchSerialNo from './pages/claim/SearchSerialNo';

import { UserAuth } from './context/AuthContext'
// ----------------------------------------------------------------------

export default function Router() {

  const { user } = UserAuth()

  const routes = useRoutes([
    {
      path: '/',
      element: <Frontend />,
      children: [
        { element: <Navigate to="/home" />, index: true },
        { path: 'home', element: <HomePage /> },
        { path: 'search-screen-replacement-warranty-registration', element: <SearchRegistration />},
        { path: 'terms-and-conditions', element: <TermAndConditions /> },
        { path: 'cambodia-warranty-registration', element: <CambodiaRegistration />},
        { path: 'terms-and-conditions-iphone16-series', element: <TermAndConditionsIphone16 />},
      ],
    },
    {
      path: '/kh',
      element: <Frontendkh />,
      children: [
        { element: <Navigate to="/home" />, index: true },
        { path: 'home', element: <HomePagekh /> },
        { path: 'search-screen-replacement-warranty-registration', element: <SearchRegistrationkh />},
        { path: 'terms-and-conditions', element: <TermAndConditionskh /> },
        { path: 'cambodia-warranty-registration', element: <CambodiaRegistrationkh />},
        { path: 'terms-and-conditions-iphone16-series', element: <TermAndConditionsIphone16 />},
      ],
    },
    {
      path: 'login',
      element: <LoginPage />,
    },
    {
      path: 'resetpassword',
      element: <ForgotPassword />,
    },
    {
      path: '/dashboard',
      element: <Navigate to="/login" replace />,
    },
    {
      path: '/dashboard/*',
      element: <Navigate to="/login" replace />,
    },
    {
      element: <SimpleLayout />,
      children: [
        { element: <Navigate to="/home" />, index: true },
        { path: '404', element: <Page404 /> },
        { path: '*', element: <Navigate to="/404" /> },
      ],
    },
    {
      path: '*',
      element: <Navigate to="/404" replace />,
    },
  ]);

  const routesSuperAdmin = useRoutes([
    {
      path: '/',
      element: <Frontend />,
      children: [
        { element: <Navigate to="/home" />, index: true },
        { path: 'home', element: <HomePage /> },
        { path: 'search-screen-replacement-warranty-registration', element: <SearchRegistration />},
        { path: 'terms-and-conditions', element: <TermAndConditions /> },
        { path: 'cambodia-warranty-registration', element: <CambodiaRegistration />},
        { path: 'terms-and-conditions-iphone16-series', element: <TermAndConditionsIphone16 />},
        
      ],
    },
    {
      path: '/kh',
      element: <Frontendkh />,
      children: [
        { element: <Navigate to="/home" />, index: true },
        { path: 'home', element: <HomePagekh /> },
        { path: 'search-screen-replacement-warranty-registration', element: <SearchRegistrationkh />},
        { path: 'terms-and-conditions', element: <TermAndConditionskh /> },
        { path: 'cambodia-warranty-registration', element: <CambodiaRegistrationkh />},
        { path: 'terms-and-conditions-iphone16-series', element: <TermAndConditionsIphone16 />},
      ],
    },
    {
      path: '/login',
      element: <Navigate to="/dashboard/app" replace />,
    },
    {
      path: '/dashboard',
      element: <DashboardLayout />,
      children: [
        { element: <Navigate to="/dashboard/app" />, index: true },
        { path: 'app', element: <DashboardAppPage /> },
        { path: 'user', element: <UserPage /> },
        { path: 'new_user', element: <NewUserPage /> },
        
        { path: 'serialno', element: <SerialNoPage /> },
        { path: 'bulk_upload_serialno', element: <BulkUploadSerialNumberPage /> },
        { path: 'registereddevice', element: <DevicePage /> },
        { path: 'bulk_upload_device', element: <BulkUploadDevicePage /> },
        { path: 'purchased_serialno', element: <PurchasedImeiPage /> },
        { path: 'new_purchased_serialno', element: <NewPurchasedImei /> },

        { path: 'new_device', element: <NewDeviceRegistrationPage /> },
        { path: 'search_device', element: <SearchDevicePage /> },
        { path: 'all_device', element: <AllDevicePage /> },
        { path: 'download_data', element: <DownloadDataPage /> },

        { path: 'search_claim', element: <SearchSerialNo /> },
        { path: 'dashboard_claim', element: <DashboardClaimPage /> },
        { path: 'submitted_claim', element: <SubmittedClaimPage /> },
      ],
    },
    {
      element: <SimpleLayout />,
      children: [
        { element: <Navigate to="/home" />, index: true },
        { path: '404', element: <Page404 /> },
        { path: '*', element: <Navigate to="/404" /> },
      ],
    },
    {
      path: '*',
      element: <Navigate to="/404" replace />,
    },
  ]);


  const routesEditor = useRoutes([
    {
      path: '/',
      element: <Frontend />,
      children: [
        { element: <Navigate to="/home" />, index: true },
        { path: 'home', element: <HomePage /> },
        { path: 'search-screen-replacement-warranty-registration', element: <SearchRegistration />},
        { path: 'terms-and-conditions', element: <TermAndConditions /> },
        { path: 'cambodia-warranty-registration', element: <CambodiaRegistration />},
        { path: 'terms-and-conditions-iphone16-series', element: <TermAndConditionsIphone16 />},
        
      ],
    },
    {
      path: '/kh',
      element: <Frontendkh />,
      children: [
        { element: <Navigate to="/home" />, index: true },
        { path: 'home', element: <HomePagekh /> },
        { path: 'search-screen-replacement-warranty-registration', element: <SearchRegistrationkh />},
        { path: 'terms-and-conditions', element: <TermAndConditionskh /> },
        { path: 'cambodia-warranty-registration', element: <CambodiaRegistrationkh />},
        { path: 'terms-and-conditions-iphone16-series', element: <TermAndConditionsIphone16 />},
      ],
    },
    {
      path: '/login',
      element: <Navigate to="/dashboard/registereddevice" replace />,
    },
    {
      path: '/dashboard',
      element: <DashboardLayout />,
      children: [
        { element: <Navigate to="/dashboard/registereddevice" />, index: true },
        { path: 'registereddevice', element: <DevicePage /> },
        { path: 'search_device', element: <SearchDevicePage /> },
        { path: 'search_claim', element: <SearchSerialNo /> },
        { path: 'dashboard_claim', element: <DashboardClaimPage /> },
      ],
    },
    {
      element: <SimpleLayout />,
      children: [
        { element: <Navigate to="/home" />, index: true },
        { path: '404', element: <Page404 /> },
        { path: '*', element: <Navigate to="/404" /> },
      ],
    },
    {
      path: '*',
      element: <Navigate to="/404" replace />,
    },
  ]);

  const routesManager = useRoutes([
    {
      path: '/',
      element: <Frontend />,
      children: [
        { element: <Navigate to="/home" />, index: true },
        { path: 'home', element: <HomePage /> },
        { path: 'search-screen-replacement-warranty-registration', element: <SearchRegistration />},
        { path: 'terms-and-conditions', element: <TermAndConditions /> },
        { path: 'cambodia-warranty-registration', element: <CambodiaRegistration />},
        { path: 'terms-and-conditions-iphone16-series', element: <TermAndConditionsIphone16 />},
        
      ],
    },
    {
      path: '/kh',
      element: <Frontendkh />,
      children: [
        { element: <Navigate to="/home" />, index: true },
        { path: 'home', element: <HomePagekh /> },
        { path: 'search-screen-replacement-warranty-registration', element: <SearchRegistrationkh />},
        { path: 'terms-and-conditions', element: <TermAndConditionskh /> },
        { path: 'cambodia-warranty-registration', element: <CambodiaRegistrationkh />},
        { path: 'terms-and-conditions-iphone16-series', element: <TermAndConditionsIphone16 />},
      ],
    },
    {
      path: '/login',
      element: <Navigate to="/dashboard/dashboard_claim" replace />,
    },
    {
      path: '/dashboard',
      element: <DashboardLayout />,
      children: [
        { element: <Navigate to="/dashboard/dashboard_claim" />, index: true },
        { path: 'user', element: <UserPage /> },
        { path: 'new_user', element: <NewUserPage /> },
        { path: 'registereddevice', element: <DevicePage /> },
        { path: 'search_device', element: <SearchDevicePage /> },
        { path: 'search_claim', element: <SearchSerialNo /> },
        { path: 'dashboard_claim', element: <DashboardClaimPage /> },
      ],
    },
    {
      element: <SimpleLayout />,
      children: [
        { element: <Navigate to="/home" />, index: true },
        { path: '404', element: <Page404 /> },
        { path: '*', element: <Navigate to="/404" /> },
      ],
    },
    {
      path: '*',
      element: <Navigate to="/404" replace />,
    },
  ]);

  const routesAdmin = useRoutes([
    {
      path: '/',
      element: <Frontend />,
      children: [
        { element: <Navigate to="/home" />, index: true },
        { path: 'home', element: <HomePage /> },
        { path: 'search-screen-replacement-warranty-registration', element: <SearchRegistration />},
        { path: 'terms-and-conditions', element: <TermAndConditions /> },
        { path: 'cambodia-warranty-registration', element: <CambodiaRegistration />},
        { path: 'terms-and-conditions-iphone16-series', element: <TermAndConditionsIphone16 />},
        
      ],
    },
    {
      path: '/kh',
      element: <Frontendkh />,
      children: [
        { element: <Navigate to="/home" />, index: true },
        { path: 'home', element: <HomePagekh /> },
        { path: 'search-screen-replacement-warranty-registration', element: <SearchRegistrationkh />},
        { path: 'terms-and-conditions', element: <TermAndConditionskh /> },
        { path: 'cambodia-warranty-registration', element: <CambodiaRegistrationkh />},
        { path: 'terms-and-conditions-iphone16-series', element: <TermAndConditionsIphone16 />},
      ],
    },
    {
      path: '/login',
      element: <Navigate to="/dashboard/app" replace />,
    },
    {
      path: '/dashboard',
      element: <DashboardLayout />,
      children: [
        { element: <Navigate to="/dashboard/app" />, index: true },
        { path: 'app', element: <DashboardAppPage /> },
        { path: 'user', element: <UserPage /> },
        { path: 'new_user', element: <NewUserPage /> },
        { path: 'registereddevice', element: <DevicePage /> },
        { path: 'search_device', element: <SearchDevicePage /> },
        { path: 'search_claim', element: <SearchSerialNo /> },
        { path: 'dashboard_claim', element: <DashboardClaimPage /> },
      ],
    },
    {
      element: <SimpleLayout />,
      children: [
        { element: <Navigate to="/home" />, index: true },
        { path: '404', element: <Page404 /> },
        { path: '*', element: <Navigate to="/404" /> },
      ],
    },
    {
      path: '*',
      element: <Navigate to="/404" replace />,
    },
  ]);


  let _userRole = "";

  if(user && user.reloadUserInfo && user.reloadUserInfo.customAttributes){
    _userRole = JSON.parse(user.reloadUserInfo.customAttributes).role;
  }

  if(user === "none"){
    return (
      <Grid style={{ width: "100%", display: "flex", alignItems: "center",  flexDirection: "column"  }}>
        <Grid style={{ width: "100%", marginTop: 60, maxWidth: 1200, display: "flex", padding: 25, height: "80vh",
        flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
          <Box sx={{ display: 'flex' }}>
            <CircularProgress color="inherit" />
          </Box>
        </Grid>
      </Grid>
    );
  }

  console.log(_userRole)

  if(user && _userRole === "super_admin")
  {
    return routesSuperAdmin
  }

  if(user && _userRole === "editor")
  {
    return routesEditor
  }

  if(user && _userRole === "manager")
  {
    return routesManager
  }

  if(user && _userRole === "admin")
  {
    return routesAdmin
  }

  return routes;
}
