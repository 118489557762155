import { Helmet } from 'react-helmet-async';
import { useState } from 'react';
// @mui
import { Stack, Typography, Grid, Button } from '@mui/material';

// ----------------------------------------------------------------------

export default function HomePage() {

  return (
    <>
      <Helmet>
        <title> Home | Falcon </title>
      </Helmet>

      <Grid style={{ width: "100%", backgroundColor: 'black', display: "flex",justifyContent: "center", alignItems: "center",  flexDirection: "column"  }}>

        <Grid style={{ marginTop: 30, width: "100%", display: "flex", padding: 25,  paddingTop: 60, paddingBottom: 80,
            flexDirection: "column",  alignItems: "center", backgroundColor: "black" }}>
            <Typography style={{ fontWeight: 700, fontSize: 36, color: '#cecece' }}>
            Cracked your screen? No worries, if you buy X/A.
            </Typography>
            <Typography style={{ fontWeight: 700, fontSize: 24, color: '#cecece' }}>
            Kindly register or check your screen replacement warranty for iPhone 16 series here.
            </Typography>
        </Grid>

        <Grid style={{ display: "flex", justifyContent: "center", alignItems:"center", flexDirection:"column", backgroundColor: "black" }}>
          <Grid style={{ backgroundColor: "black", padding: 15, paddingBottom: 0 }}>
            <img src="/assets/images/banners/banner-1.jpg" alt='banner1' width={800}/>
          </Grid>

          <Grid style={{ backgroundColor: "black", padding: 15, paddingBottom: 0 }}>
              <img src="/assets/falcontrust-logo.png" alt='falcontrust-logo' width={250}/>
            </Grid>
        </Grid>

        <Grid style={{ width: "100%", display: "flex", padding: 25, paddingBottom: 170,
          flexDirection: "column",  alignItems: "center", backgroundColor: "black" }}>
          <Button variant="contained" style={{ width: 200, marginTop: 20 }} href={"/search-screen-replacement-warranty-registration"}   disableElevation>
          Search Registration
          </Button>

          <Button variant="contained" href={"/cambodia-warranty-registration"} style={{ width: 200, marginTop: 20 }} disableElevation>
          Register Now
          </Button>
        </Grid>

        
      </Grid>
    </>
  );
}
