// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyAVgHwu_fzmXe1vQzrPFUPBP4pnx2Zr8IQ",
  authDomain: "azteq-dc1d6.firebaseapp.com",
  databaseURL: "https://azteq-dc1d6-default-rtdb.firebaseio.com",
  projectId: "azteq-dc1d6",
  storageBucket: "azteq-dc1d6.firebasestorage.app",
  messagingSenderId: "747452880628",
  appId: "1:747452880628:web:9b5d8dbf3cfc0bf7f630a1"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);


export default app;