import { Helmet } from 'react-helmet-async';
import { useState } from 'react';
// @mui
import { Container, Stack, Typography, Grid, Button } from '@mui/material';
import Divider from '@mui/material/Divider';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import AccountCircle from '@mui/icons-material/AccountCircle';
import SearchIcon from '@mui/icons-material/Search';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Input from '@mui/material/Input';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import FormatBoldIcon from '@mui/icons-material/FormatBold';
import FormatItalicIcon from '@mui/icons-material/FormatItalic';
import FormatUnderlinedIcon from '@mui/icons-material/FormatUnderlined';
import FormatColorFillIcon from '@mui/icons-material/FormatColorFill';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import * as React from 'react';
import Box from '@mui/material/Box';

import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

// ----------------------------------------------------------------------

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

function createData(model, storage, coverage) {
  return { model, storage, coverage };
}

const rows = [
  createData('iPhone 15 (128GB)', "850", "300"),
  createData('iPhone 15 (256GB)', "950", "300"),
  createData('iPhone 15 (512GB)', "1100", "300"),
  createData('iPhone 15 Plus (128GB)', "950", "350"),
  createData('iPhone 15 Plus (256GB)', "1100", "350"),
  createData('iPhone 15 Plus (512GB)', "1250", "350"),
  createData('iPhone 15 Pro (128GB)', "1100", "350"),
  createData('iPhone 15 Pro (256GB)', "1150", "350"),
  createData('iPhone 15 Pro (512GB)', "1400", "350"),
  createData('iPhone 15 Pro (1TB)', "1600", "350"),
  createData('iPhone 15 Pro Max (256GB)', "1300", "400"),
  createData('iPhone 15 Pro Max (512GB)', "1500", "400"),
  createData('iPhone 15 Pro Max (1TB)', "1700", "400"),
];


export default function TermAndConditions() {

  const submitHandler = (e) => {
    // console.log('submit called');
    e.preventDefault();
  }

  const [alignment, setAlignment] = React.useState('cambodia');

  const handleChange = (event, newAlignment) => {
    setAlignment(newAlignment);
  };

  return (
    <>
      <Helmet>
        <title> iPhone 16 Series | Terms & Conditions </title>
      </Helmet>

      <Grid style={{ width: "100%", display: "flex", alignItems: "center",  flexDirection: "column"  }}>


        <Grid style={{ width: "100%", marginTop: 60, maxWidth: 1200, display: "flex", padding: 25, 
         flexDirection: "column", alignItems: "center" }}>
          <Typography style={{ fontWeight: 700, fontSize: 30, color: '#000000' }}>
          iPhone 16 Series - Terms & Conditions
            </Typography>

          <Grid style={{ width: "100%", marginTop: 60, maxWidth: 1200, display: "flex", padding: 25,  flexDirection: "column" }}>
              <Typography style={{ fontWeight: 700, fontSize: 18, color: '#000000' }}>
              THIS IS A SERVICE CONTRACT PROVIDING FOR THE FOLLOWING:
                </Typography>

                <Typography style={{ fontWeight: 700, fontSize: 14, color: '#000000' }}>
                i) ACCIDENT DAMAGE SCREEN CRACK ONLY - LIMITED TO ONE (1) TIME CLAIM ONLY FOR SCREEN CRACK REPAIR WITHIN THE APPROVED TERRITORY.
                </Typography>

                <Typography style={{ fontWeight: 700, fontSize: 18, color: '#000000', marginTop: 20 }}>
                1)	THE PLAN.
                </Typography>

              <Typography style={{ fontWeight: 100, fontSize: 14, color: '#000000', marginTop: 20 }}>
              By activating the Plan in accordance with these Terms, you hereby agree to be bound by these Terms.
These Terms govern the ONE (01) Time Repair or Replacement Services (“Service”) caused by
Accidental Damage within the approved Territory provided to you by FALCON TECH under the Plan
for Apple-branded smartphone hard bundle or soft bundle contained in its original packaging (“Covered
Product”).

              </Typography>
              <Typography style={{ fontWeight: 100, fontSize: 14, color: '#000000', marginTop: 20 }}>
              Accidental Damage means an unforeseen event that causes damage in particular screen crack , which
is not the result of the deliberate or the negligent act of you.
              </Typography>
              <Typography style={{ fontWeight: 100, fontSize: 14, color: '#000000', marginTop: 20 }}>
              The Plan is not transferable and or refundable in any circumstances unless consented
by FALCON TECH. In the event, the Covered Product under the Plan is replaced directly by the
original manufacturer due to the original manufacturer’s recall, you must submit to FALCON TECH the
original manufacturer’s delivery receipt and serial number of the new product within three (3) days of
the replacement.
              </Typography>

              <Typography style={{ fontWeight: 700, fontSize: 18, color: '#000000', marginTop: 20 }}>
              2)	WHEN THE PLAN COVERAGE BEGINS AND ENDS.
                </Typography>

              <Typography style={{ fontWeight: 100, fontSize: 14, color: '#000000', marginTop: 20 }}>
              Coverage for the Plan begins from the phone activation date and ends on the 12th month from the
phone activation date (“Coverage Period”). The original FALCON warranty card for your Phone is part
of your service contract with FALCON TECH.. Without the original Falcon warranty card, your
coverage will be terminated.

              </Typography>

              <Typography style={{ fontWeight: 700, fontSize: 18, color: '#000000', marginTop: 20 }}>
              3)	WHAT IS COVERED?
                </Typography>

              <Typography style={{ fontWeight: 100, fontSize: 14, color: '#000000', marginTop: 20 }}>
              3.1 Coverage for:
              </Typography>

              <Typography style={{ fontWeight: 100, fontSize: 14, color: '#000000', marginTop: 20, paddingLeft: 15 }}>
              i) ONE (01) Time Carry in Screen Crack Repair or Replacement Services
              </Typography>
              <Typography style={{ fontWeight: 100, fontSize: 14, color: '#000000', marginTop: 20, paddingLeft: 15 }}>
              If during the Coverage Period, you submit a valid claim by submitting to CACTUS REPAIR, the
FALCON Appointed Apple Authorized Service Provider that a screen crack incident happened due to
accidental damage on your Covered Product, CACTUS REPAIR will either (a) repair the Covered
Product’s screen, using new or refurbished parts that are equivalent to new in performance and
reliability, or (b) exchange the Covered Product’s screen with a replacement product that is new or
equivalent to new in performance and reliability, and is at least functionally equivalent to the original
product. If CACTUS REPAIR exchanges the Covered Product, the title to the original product shall be
transferred to CACTUS REPAIR and the replacement product shall be your property, and the coverage
of the Plan shall be terminated immediately.
              </Typography>
              <Typography style={{ fontWeight: 100, fontSize: 14, color: '#000000', marginTop: 20, paddingLeft: 15 }}>
              You are required to consult your Covered Product’s original manufacturer for any technical support to
determine if the failure to operate is due to circumstances that may be corrected by yourself. If the
nature of the defect is not covered by the Plan, you will be charged for the cost of the service repair.
A diagnostic service fee will be charged if you decide not to proceed with the service repair or when
defect is found.

              </Typography>
              <Typography style={{ fontWeight: 100, fontSize: 14, color: '#000000', marginTop: 20, paddingLeft: 15 }}>
              If the nature of the defect is covered by the Plan, you will be charged 50 USD for service fees to
CACTUS REPAIR, the FALCON Appointed Apple Authorised Service Provider in order to
receive the Services.

              </Typography>
              <Typography style={{ fontWeight: 100, fontSize: 14, color: '#000000', marginTop: 20 }}>
              3.2 Covered Product
              </Typography>
              <Typography style={{ fontWeight: 100, fontSize: 14, color: '#000000', marginTop: 20, paddingLeft: 15 }}>
              The Covered Product must be an Apple-branded, Brand New Smart Phone smartphone that is
contained in its original packaging with valid manufacturer’s warranty are eligible for the coverage.
Further, the Plan only covers a Covered Product which:
              </Typography>
              <Typography style={{ fontWeight: 100, fontSize: 14, color: '#000000', marginTop: 20, paddingLeft: 15 }}>
              (1) is purchased from FALCON TECH the authorized distributor of the Covered Product. Country code:
X/A 
              </Typography>
              <Typography style={{ fontWeight: 100, fontSize: 14, color: '#000000', marginTop: 20, paddingLeft: 15 }}>
              (2) must contain the certification mark from the original manufacturer of the Covered Product under
law and be certified for sale and use in the approved Territory by the relevant certification agency as
required under law.
              </Typography>
              <Typography style={{ fontWeight: 100, fontSize: 14, color: '#000000', marginTop: 20, paddingLeft: 15 }}>
              (3) has a Manufacturer warranty of 12 months that is valid in the approved Territory included with the
              Covered Product at the time of purchase.
              </Typography>
              <Typography style={{ fontWeight: 100, fontSize: 14, color: '#000000', marginTop: 20, paddingLeft: 15 }}>
              (4) is used for domestic and personal use only; and


              </Typography>
              <Typography style={{ fontWeight: 100, fontSize: 14, color: '#000000', marginTop: 20, paddingLeft: 15 }}>
              (5) shall not be used in any commercial, rental or for-profit generation purpose within a multiple user
organization or for commercial usage in residential areas.

              </Typography>
              <Typography style={{ fontWeight: 700, fontSize: 18, color: '#000000', marginTop: 20 }}>
              4)	WHAT IS NOT COVERED?
                </Typography>

              <Typography style={{ fontWeight: 100, fontSize: 14, color: '#000000', marginTop: 20 }}>
              4.1	The Plan does not apply to:
              </Typography>

              <Typography style={{ fontWeight: 100, fontSize: 14, color: '#000000', marginTop: 20, paddingLeft: 15 }}>
              {`(1) mechanical and or electrical breakdown of Covered Product; (2) a Covered Product with the serial
number, part number, proof of purchase, warranty certificate and or E-Certificate that has been altered,
defaced, destroyed and or duplicated. (3) a Covered Product with removed and or altered serial
numbers, part number and or IMEI; (4) any damage or loss caused by the alteration, modification,
repair and or maintenance of the Covered Product by any party not authorized by original manufacturer
and or FALCON TECH. (5) any damage or loss caused by wilfully, recklessly, or intentionally; (6) any
damage or loss caused by negligence or carelessness; (7) any damage or loss caused by fraud; (8)
any damage or loss caused by sabotage; (9) any damage or loss caused by wear and tear,
degeneration, rust, mould, discoloration or cosmetic defects including defects to paintwork or product
finish; (10) any damage or loss that cannot be proved in accordance with the requirements of the
insurer; (11) any damage or loss caused by including without limitation by earthquake, volcanic
eruptions, tsunami; (12) any damage or loss caused by war, riots or uprising; (13) any damage or loss
caused by the execution of legal rights by national or public bodies or any third party, seizure,
requisition, and confiscation; (14) any damage or loss caused by nuclear fuel substances or
radioactivity; (15) any damage or loss caused by computer viruses or any defect or any error in any
software used upon or in association with the Covered Product; (16) any damage or loss caused by
manufacturer's defects inherent in the Covered Product; (17) any damage or loss caused by wear and
tear to cables, cord assembly, battery packs, display panels, adaptors, covers, buttons, speakers,
computer keyboards and mouse; (18) any damage or loss of Peripheral equipment or components
that do not have independent functions. This includes, without limitation, equipment or components
worn out through the use of the Covered Product and the accessories bundled or purchased
separately with the Product (including Battery/Charger/Earphone); (19) any damage or loss caused
by neglect, misuse, abuse, improper installation, improper usage or wrong electrical supply/voltage or
failure to operate the Covered Product in accordance with the instructions provided by the
manufacturer of the Covered Product; (20) any damage or loss caused by voltage converter and or
applying incorrect voltage to the Covered Product; (21) any damage of the Covered Product resulting
from over running, excessive pressure, short circuiting, self-heating, arching or leakage of electricity
(Electrical Damages); (22) any damage of the Covered Product resulting from explosion or machinery
breakdown; (23) any damage by fire, theft, burglary, neglect, misuse, or abuse. Acts of god including
but not limited to earthquake, storm or tempest (wind), sand, water, flood, rising water, lightning,
malicious damage, aircraft, vehicle impact, corrosion, battery leakage, power outages or surges,
inadequate or improper voltage, or current, animal or insect infestation; (24) any damage or loss of
the Covered Product function due to manufacturer design flaw and or defect resulting to the original
manufacturer’s Covered Product recall; (25) appearance or structural items, such as housing, case or
frame, decorative parts or parts designated to be replaced periodically during life of the Covered
Product, add‐on items, accessories or attachments, external wiring and cabling, battery chargers,
racks, bins, knobs, handles, antennas, remote control; or (26) any software programs, whether
provided with the Covered Product and or installed subsequently. Any technical or other support, such
as assistance with "how‐to" questions and those regarding product set‐up and installation. (27) any
Covered Product that is inoperable due to unauthorised modifications or has failed due to catastrophic
damage, such as the covered product separating into multiple pieces, are ineligible for service. (28)
any Covered Product does not approve by FALCON TECH.
`}
              </Typography>
              
              <Typography style={{ fontWeight: 700, fontSize: 18, color: '#000000', marginTop: 20 }}>
              5)	HOW TO OBTAIN A SERVICE?

                </Typography>

              <Typography style={{ fontWeight: 100, fontSize: 14, color: '#000000', marginTop: 20 }}>
              {`You may obtain the Services by visiting CACTUS REPAIR, the FALCON Appointed Apple
AuthorisedService Provider or dropping your phone at the store you purchased from which will then
be transferred to CACTUS REPAIR. You are required to provide your Phone Serial Number and
original Falcon Warranty Card and Detailed Description on the Nature of Damage. You are required
to file the service request within 3 days after the incident. Otherwise, you will not receive any benefits
in respect of the service request. `}
              </Typography>


              <Typography style={{ fontWeight: 700, fontSize: 18, color: '#000000', marginTop: 20 }}>
              6)	MODE OF SERVICE DELIVERY
                </Typography>

              <Typography style={{ fontWeight: 100, fontSize: 14, color: '#000000', marginTop: 20 }}>
              {`The Service will be performed by CACTUS REPAIR, the Apple authorized service provider for the
Covered Product and the Service shall be completed in accordance to the service provider terms and
conditions. Once the Service is completed, you will be notified by the authorized service provider for
the return of your Covered Product. FALCON TECH reserves the right to change the method by which
FALCON TECH may provide repair or replacement service to you, and your Covered Product’s
eligibility to receive a particular method of service. The Service will be limited to the options available
in the country where Service is requested. Service options, parts availability and response times may
vary according to country. You may be responsible for shipping and handling charges if the Covered
Product cannot be serviced in the country it is in. 
`}
              </Typography>

              <Typography style={{ fontWeight: 700, fontSize: 18, color: '#000000', marginTop: 20 }}>
              7)	COVERED PRODUCTS LOST
                </Typography>

              <Typography style={{ fontWeight: 100, fontSize: 14, color: '#000000', marginTop: 20 }}>
              {`In the event the Covered Product is lost in the care of FALCON TECH in accordance with these Terms,
FALCON TECH will only be liable to replace the Covered Product with you in the following manner:
(a) If the Covered Product is less than six (6) months old from the date of purchase, FALCON TECH
will only provide a new replacement device of the same make and model; and (b) If the Covered
Product is more than six (6) months old from the date of purchase, FALCON TECH will only provide a
refurbished replacement device of the same make and model, if you are able to provide documentation
evidencing the date of purchase of the Covered Product to FALCON TECH’ s satisfaction and that a
written claim for a replacement is made within thirty (30) days from the date the Covered Product has
been notified to you that it has been lost. Parties agree that the acceptance of a replacement device
is deemed to be the full, final and adequate compensation for any damage or loss suffered caused by
the loss of Covered Product.`}
              </Typography>

              <Typography style={{ fontWeight: 700, fontSize: 18, color: '#000000', marginTop: 20 }}>
              8)	YOUR RESPONSIBILITIES
                </Typography>

              <Typography style={{ fontWeight: 100, fontSize: 14, color: '#000000', marginTop: 20 }}>
              {`To receive the Services under the Plan, you agree to comply with the following: (a) provide all the
supporting documents as required under these Terms; (b) respond to requests for information,
including but not limited to the Covered Product serial number, model, version of the operating system
and software installed, any peripherals devices connected or installed on the Covered Product, any
error messages displayed, actions taken before the Covered Product experienced the issue and steps
taken to resolve the issue; (c) comply with instructions from FALCON TECH, including but not limited
to refraining from packing the Covered Product in accordance with shipping instructions. (d) BACK UP
THE SOFTWARE AND DATA RESIDING ON THE COVERED PRODUCT. CACTUS REPAIR, THE
APPLE AUTHORISED SERVICE PROVIDER MAY REINSTALL THE COVERED PRODUCT’S
ORIGINAL SOFTWARE CONFIGURATION AND SUBSEQUENT UPDATE RELEASES WHILE
PERFORMING SERVICE, WHICH WILL RESULT IN THE DELETION OF ALL SOFTWARE AND
DATA THAT RESIDED ON THE COVERED PRODUCT PRIOR TO SERVICE. DURING SERVICE,
CACTUS REPAIR, THE APPLE AUTHORISED SERVICE PROVIDER WILL DELETE THE
CONTENTS OF THE COVERED PRODUCT AND REFORMAT THE STORAGE MEDIA. FALCON
TECH will return your Covered Product as the Covered Product was originally configured, subject to
applicable updates. FALCON TECH may install IOS updates as part of service. Third party
applications installed on the Covered Product may not be compatible or work with the Covered Product
as a result of the IOS update. You will be responsible for reinstalling all other software programs, data
and passwords. (e) You are required to make the payment for the additional cost (if any) of service
repair if the nature of the damage is not covered by the Plan.`}
              </Typography>
                
              <Typography style={{ fontWeight: 700, fontSize: 18, color: '#000000', marginTop: 20 }}>
              9)	LIMITATION OF LIABILITY
                </Typography>

              <Typography style={{ fontWeight: 100, fontSize: 14, color: '#000000', marginTop: 20 }}>
              {`TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, FALCON TECH AND ITS
EMPLOYEES AND AGENTS AND ITS APPLE APPOINTED SERVICE PROVIDER CACTUS
REPAIR WILL UNDER NO CIRCUMSTANCES BE LIABLE TO YOU FOR ANY DIRECT OR
INDIRECT OR CONSEQUENTIAL DAMAGES, INCLUDING BUT NOT LIMITED TO ANY LOSS
INCURRED DUE TO ANY DELAY IN RENDERING ANY SERVICES RELATED TO THE PLAN
AND/OR LOSS OF USE DURING THE PERIOD THAT YOUR COVERED PRODUCT IS
UNDERGOING THE SERVICE AT THE AUTHORISED SERVICE PROVIDER AND/OR AWAITING
FOR THE REPLACEMENT PARTS FOR THE COVERED PRODUCT, COSTS OF RECOVERING,
REPROGRAMMING, OR REPRODUCING ANY PROGRAM OR DATA OR THE FAILURE TO
MAINTAIN THE CONFIDENTIALITY OF DATA, ANY LOSS OF BUSINESS, PROFITS, REVENUE
OR ANTICIPATED SAVINGS, RESULTING FROM FALCON TECH’S OBLIGATIONS UNDER THIS
PLAN. TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, THE LIMIT OF FALCON
TECH AND ITS EMPLOYEES AND AGENT’S LIABILITY TO YOU ARISING UNDER THE PLAN
SHALL NOT EXCEED THE ORIGINAL PRICE PAID FOR THE PLAN. FALCON TECH
SPECIFICALLY DOES NOT WARRANT THAT (i) IT WILL BE ABLE TO REPAIR OR REPLACE
COVERED PRODUCT WITHOUT RISK TO OR LOSS OF PROGRAMS OR DATA, (ii) IT WILL
MAINTAIN THE CONFIDENTIALITY OF DATA, OR (iii) THAT THE OPERATION OF THE PRODUCT
WILL BE UNINTERRUPTED OR ERROR-FREE. FALCON TECH AT ITS SOLE DISCRETION, MAY
REPLACE YOUR COVERED PRODUCT WITH ONE OF A LIKE KIND AND QUALITY IF THE
COVERED PRODUCT IS NOT REPAIRABLE DUE TO THE PART BECOMING OBSOLETE OR
BEYOND ECONOMIC REPAIR. DUE TO TECHNOLOGICAL ADVANCES, THE REPLACEMENT
PRODUCT MAY BE OF LOWER RETAIL VALUE THAN THE ORIGINAL COVERED PRODUCT.
REPLACEMENT PARTS WILL BE ORIGINAL OR THIRD-PARTY ORIGINAL EQUIPMENT
MANUFACTURER’S PARTS THAT PERFORM TO FACTORY SPECIFICATIONS OF THE
COVERED PRODUCT AND SHALL BE DETERMINED AT FALCON TECH SOLE DISCRETION.
FAULTY PARTS AND/OR DEVICES MUST BE RETURNED AND BECOMES THE PROPERTY OF
FALCON TECH. ANY SUCH REPLACEMENT WILL IMMEDIATELY TERMINATE THE PLAN. `}
              </Typography>

              <Typography style={{ fontWeight: 700, fontSize: 18, color: '#000000', marginTop: 20 }}>
              10)	DATA STORAGE
                </Typography>

              <Typography style={{ fontWeight: 100, fontSize: 14, color: '#000000', marginTop: 20 }}>
              {`If your Covered Product is capable of storing any user data, it is possible that repairing your Covered
Product may result in the loss of your data regularly. FALCON TECH will not be liable for any loss of
data or loss of use during the period that your Covered Product is undergoing the service at CACTUS
REPAIR the Apple authorized service provider.
`}
              </Typography>

              <Typography style={{ fontWeight: 700, fontSize: 18, color: '#000000', marginTop: 20 }}>
              11)	TERMINATION
                </Typography>

              <Typography style={{ fontWeight: 100, fontSize: 14, color: '#000000', marginTop: 20 }}>
              {`The Plan shall be terminated immediately without any refund of the price paid for the Plan if any of the
following events occur: (a) expiry of the term of the Plan; or (b) when the Covered Product has been
repaired and or replaced; or (c) the disposal, subsequent sale, lost or repossession of the Covered
Product by you; or (d) the unauthorized repair or modification of the Covered Product; or (e) upon
discovery of fraud or misrepresentation; or (f) breach of any of the Terms. `}
              </Typography>

              <Typography style={{ fontWeight: 700, fontSize: 18, color: '#000000', marginTop: 20 }}>
              12)	PERSONAL DATA PROTECTION 
                </Typography>

              <Typography style={{ fontWeight: 100, fontSize: 14, color: '#000000', marginTop: 20 }}>
              {`Upon successful activation of your Plan, you agree that any information or data disclosed to FALCON
TECH under this Plan is not confidential or proprietary to you. Furthermore, you hereby agree and
authorize FALCON TECH and its authorized third parties such as service providers, contractors,
insurers and or agents to collect, use and disclose personal data about you and or about other persons
furnished by you, now and in the future, on manual and or computer files for administrative and
customer care purposes (together with such other information supplied to or obtained by FALCON
TECH separately) including: (i) to allow FALCON TECH to investigate, assess and or pay service
repair fees under your Plan (ii) to provide you and or other persons with information on the products
and services for marketing purposes; (iii) for market research and (iv) tracking of sales data. FALCON
TECH and or its authorized third parties may contact you and or these people by post and telephone
for these purposes. If there is any personal data relating to other individuals, you must have obtained
prior consent from them (or if they are lacking in legal capacity, from their legal representatives,
guardians or parents as the case may be) for FALCON TECH and its abovementioned authorized third
parties to collect, use and disclose their personal data for the above purposes, and as if they were
you. All personal data you provide shall be accurate and complete, and you will inform FALCON TECH
of any changes to the personal data to your knowledge as soon as practicable. If you agree, FALCON
TECH may also contact you by e-mail, SMS text or fax for these purposes. You may opt out at any
time allowing FALCON TECH or its authorized agents to process your Information by writing to
FALCON TECH.
`}
              </Typography>

              <Typography style={{ fontWeight: 700, fontSize: 18, color: '#000000', marginTop: 20 }}>
              13)	GENERAL
                </Typography>

              <Typography style={{ fontWeight: 100, fontSize: 14, color: '#000000', marginTop: 20 }}>
              {`(a) FALCON TECH may subcontract or assign performance of its obligations to third parties but shall
not be relieved of its obligations to you in doing so. (b) FALCON TECH is not responsible for any
failures or delays in performing under the Plan that are due to events outside of FALCON TECH’ s
reasonable control. (c) This Plan is offered and valid only if you are a resident of a country in which
FALCON TECH offers the Plan. This Plan is not offered to persons who have not reached the age of
18. This Plan is not available where prohibited by law. (d) In carrying out its obligations FALCON TECH
may, at its discretion and solely for the purposes of monitoring the quality of FALCON TECH’ s
response, record part or all of the communications between you and FALCON TECH. (e) The terms
of the Plan, including the original sales receipt of the Plan and the Covered Product, prevail over any
conflicting, additional, or other terms of any purchase order or other document, and constitute your
and FALCON TECH’ s entire understanding with respect to the Plan. (f) You must purchase and
activate the Plan while your Covered Product is within the original manufacturer’s One Year limited
warranty. FALCON TECH is not obligated to renew this Plan. If FALCON TECH does offer renewal, it
will determine the price and terms. (g) With respect to any suit, action or proceedings relating to any
dispute arising out of or in connection with these Terms, each party submits to the exclusive jurisdiction
of the courts of Cambodia. (h) This Plan is offered and valid only in Cambodia. The laws of the
Cambodia govern these Terms. `}
              </Typography>


              <Typography style={{ fontWeight: 100, fontSize: 14, color: '#000000', marginTop: 20 }}>
              ** These Terms and Conditions may have been translated if We have made them available to You on our Service. You agree that the original English text shall prevail in the case of a dispute.**
              </Typography>

            </Grid>
            
        </Grid>

       
        
      </Grid>
    </>
  );
}
